import {Component, HostListener, OnInit} from '@angular/core';
import {DatePipe, TitleCasePipe} from "@angular/common";
import {ConfigService} from '../_services/helpers/config.service';
import {SupportConstants} from '../../support_constants';
import {LoggingService} from '../_services/functionality/logging.service';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

import {AuthenticationService} from "../_services/functionality/authentication.service";
import {HttpClient} from "@angular/common/http";

/** Main search page. handles before search, after search is done, and all search results. */
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  providers: [TitleCasePipe, DatePipe]
})

export class ProfileComponent implements OnInit {

  ConfigService = ConfigService;

  /** local instance of the platinum constants to be used in the template */
  constants: SupportConstants;

  /** local instance of the js object window to be used in the template */
  windowRef: Window;

  profileFilter: string;

  fullAddress: SafeHtml;

  /** @ignore */
  constructor(public authService: AuthenticationService, public sanitizer: DomSanitizer) {

    this.constants = ConfigService.spConstants;
    this.windowRef = window;
    this.profileFilter = "account";
  }

  /** @ignore */
  async ngOnInit() {

    let addrHtml = this.authService.accountData.address1 + "<br />";
    if(this.authService.accountData.address2)
      addrHtml += this.authService.accountData.address2 + "<br />";
    addrHtml += this.authService.accountData.city + ", " +
        this.authService.accountData.state + " " + this.authService.accountData.zip;

    this.fullAddress = this.sanitizer.bypassSecurityTrustHtml(addrHtml);
  }
}
