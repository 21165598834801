<div *ngIf="authService.accountData">
<nav *ngIf="authService.accountData" class="navbar navbar-inverse navbar-fixed-top theme-{{authService.accountData.theme}} main-navbar" role="navigation" id="main-nav">
  <div class="container-fluid">

    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" id="collapsed-nav-btn" data-toggle="collapse"
              data-target="#docx-navbar-collapsed" aria-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>

      <!--
       company logo whether or not it is for the unauthorized version of the header or for the normal authorized version
       -->
      <a class="navbar-brand docx-navbar-brand nav-img-container clickable" routerLink="/">
          <img alt="Brand" width="125" src="../assets/images/docxonomy_logo_sm.png">
      </a>
    </div>

    <app-authorized-header [TruncateCompanyName]="truncateCompanyName"></app-authorized-header>
    </div>
</nav>
</div>



