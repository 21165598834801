import {ErrorHandler, Injectable, Injector} from '@angular/core';

/** master global error handler.
 */
@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {
  /**
   * @ignore
   */
  constructor(private injector: Injector) {
    super();
  }

  /** override of the handle error function. it stops 'ExpressionChangedAfterItHasBeenCheckedError' errors and also if there are any other error messages in platinum constants that we should handle in a special way, we check for that has well */
  handleError(err) {

    //Log the error, but ignore certain errors:
    //NOTE: We don't want to ignore ObjectUnsubscribedError but we have not determined why it appears (which is during search result processing).  It does not appear to affect the
    //search results.
    if ((err) && (err.name !== "ObjectUnsubscribedError") && (err.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') < 0)) {
      console.error(err);
    }
  }

}
