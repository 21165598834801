import {Component, HostListener, OnInit, Pipe, PipeTransform, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from './_services/functionality/authentication.service';
import {DxTreeListComponent} from 'devextreme-angular';
import {TimeoutService} from './_services/functionality/timeout.service';
import {SupportConstants} from '../support_constants';
import {ConfigService} from './_services/helpers/config.service';
import {DeviceService} from './_services/functionality/device.service';
import {CustomTitleService} from './_services/functionality/custom-title-service.service';

// @ts-ignore
/** the main component for the entire application */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  /** local instance of the platinum constants to be used in the template */
  constants: SupportConstants;

  DeviceService = DeviceService; // Need so we can reference this enum in the template

  /** angular way of viewing the DxTreeListComponent component */
  @ViewChild(DxTreeListComponent) treeListComponent: DxTreeListComponent;

  /** helper function to check if a class if clickable
   * @param classToCheck - the class we want to check for permissions to click
   * @param allowedClasses - classes that are allowed to be clicked
   * @returns {boolean} whether or not the class is allowed to be clicked
   * */
  static checkIfClassIsClickable(classToCheck: string, allowedClasses: string[]): boolean {
    for (let i = 0; i < allowedClasses.length; i++) {
      if (classToCheck.indexOf(allowedClasses[i]) >= 0) {
        return true;
      }
    }
    return false;
  }


  /** host listener for when the document gets focus again.... used to reset the timeout and to turn off the flashing title */
  @HostListener('document:focus', ['$event']) focus() {
    this._timeoutService.resetTimeoutWithinApplication();
    this._titleService.clearFlashingTitle();
  }

  /** host listener for when the document has a scroll event.... used to reset the timeout and to turn off the flashing title */
  @HostListener('window:scroll') scrollTimeoutreset() {
    this._timeoutService.resetTimeoutWithinApplication();
    this._titleService.clearFlashingTitle();
  }

  /** host listener for when the document has a mouseover event.... used to reset the timeout and to turn off the flashing title */
  @HostListener('mouseover') mouseoverTimeoutreset() {
    this._timeoutService.resetTimeoutWithinApplication();
    this._titleService.clearFlashingTitle();
  }

  /** host listener for when the document has a mouseleave event.... used to reset the timeout and to turn off the flashing title */
  @HostListener('mouseleave') mouseleaveTimeoutreset() {
    this._timeoutService.resetTimeoutWithinApplication();
    this._titleService.clearFlashingTitle();
  }

  /** host listener for when the document has a mouseout event.... used to reset the timeout and to turn off the flashing title */
  @HostListener('mouseout') mouseoutTimeoutreset() {
    this._timeoutService.resetTimeoutWithinApplication();
    this._titleService.clearFlashingTitle();
  }

  /** keep track of when there is an orientation change
   * NOTE: We could simply reference the window object in real time, but we set the orientation to a string value
   * for convenience instead */
  @HostListener('window:orientationchange', ['$event'])
  orientationCheck(event) {

    this._deviceService.setOrientation();
  }

  /** host listener for when the state is changed... this is directly related to the window history object that the
   * browser keeps in sync. https://developer.mozilla.org/en-US/docs/Web/API/Window/history
   * @todo convert all of this logic into a state management system
   * */
  @HostListener('window:popstate', ['$event'])
  onPopState(e) {
    this._timeoutService.resetTimeoutWithinApplication();
    this._titleService.clearFlashingTitle();

    const targetLocationPath = e.target.location.pathname;

    if (!this.authService.accountData || !this.authService.accountData.id || !this.authService.authToken) {
      this._router.navigate(['/login']);
    }

    e.preventDefault();
    e.stopPropagation();
  }


  /** @ignore */
  constructor(private routerActivated: ActivatedRoute, public authService: AuthenticationService,
              private _router: Router, public _timeoutService: TimeoutService,
              public _titleService: CustomTitleService, public _deviceService: DeviceService) {

    this.constants = ConfigService.spConstants;
  }

  /** @ignore */
  ngOnInit() {

    // Register a listener for the user authenticated subject:
    this.authService.authTokenUpdate.subscribe((token) => {

      //The user subject fired with a valid user in memory, so setup the timeout:
      if (token) {

        // sets up the timeout
        this._timeoutService.setupTimeout();
      }
      else {
        this._timeoutService.clearTimeoutWithinApplication();
      }
    });
  }

  /** handles making the user active again throughout the application (once the user is made idle but they click the make active button) */
  makeActiveAgain(): void {
    this._timeoutService.makeActive = true;
    this._timeoutService.resetTimeoutWithinApplication();
  }
}

