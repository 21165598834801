<div class="col-xs-12 col-sm-12 col-md-12">
  <div class="row">
    <div class="col-lg-4"></div>
    <div class="col-lg-4">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h3 class="panel-title">Password Reset Required</h3>
        </div>
        <div class="panel-body">
          <dx-validation-group #pwRestValidationGroup>
            <app-password-input-validator
              Title=""
              [FieldValidationMessage]="'Passwords must be at least 10 characters long.'"
              [(ValidatedPassword)]="validatedNewPassword"
              [MinLength]="10">
            </app-password-input-validator>

            <dx-validation-summary></dx-validation-summary>

            <div class="btn btn-default docx-reset-button" (click)="updateUserPassword(pwRestValidationGroup)">Update Password
            </div>
          </dx-validation-group>
        </div>
      </div>
    </div>
    <div class="col-lg-4"></div>
  </div>
</div>
