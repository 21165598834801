import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AuthenticationService, LOGOUT_REASON} from '../_services/functionality/authentication.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {LoggingService} from '../_services/functionality/logging.service';
import {ConfigService} from '../_services/helpers/config.service';
import {SupportConstants} from '../../support_constants';
import {DeviceService} from '../_services/functionality/device.service';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

/** structure of a user logging in. IMPORTANT: make sure if you have this in a variable that it gets deleted as soon as its used! */
export interface UserLogin {
  /** user's email */
  email: string;

  /** user's password */
  password: string;
}

/** component/page that handles all of the login logic. */
@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    trigger('slideLeftFadeOut', [
      state('active', style({ opacity: 0, transform: 'translateX(-0.7%)' })),
      state('inactive', style({ opacity: 1, transform: 'translateX(0)' })),
      transition('active => inactive', animate('600ms')),
      transition('inactive => active', animate('300ms'))
    ]),
  ]
})
export class LoginComponent implements OnInit {

  /** temporary place where we store the email and password combination */
  model: UserLogin = {email: null, password: null};

  /** a string that is used to initiate an angular animation with */
  animationString: string;

  /** boolean to track whether or not the user forgot their password... used to show that screen */
  forgetPassword: boolean;

  /** local instance of the js object window to be used in the template */
  windowRef: Window;

  /** local instance of the platinum constants to be used in the template */
  constants: SupportConstants;

  loginBtn: HTMLButtonElement;

  /** @ignore */
  constructor(public router: Router,
              public authService: AuthenticationService,
              public _deviceService: DeviceService,
              public http: HttpClient) {

    this.windowRef = window;

    LoggingService.docxonomyLogging(94, {
      operation: 'constructor',
      subcomponent: 'login.component'
    }, '', false, false);

    this.forgetPassword = false;

    this.constants = ConfigService.spConstants;
  }

  /** @ignore */
  ngOnInit() {
    this.animationString = 'inactive';

    LoggingService.docxonomyLogging(95, {
      operation: 'ngOnInit',
      subcomponent: 'login.component'
    }, '', false, false);

    window.history.replaceState({}, '', '/');
  }


  /** reset the values to return back to the login screen */
  returnToLogin() {
    this.forgetPassword = false;
  }

  /** master login function... interacts with the authentication service to handle calling iron server.
   * @param event - form submit event... event will only be '' when we call it intentionally programmatically,
   * if it is a normal event it means that this function is getting called from a form and we need to prevent
   * the default behavior
   * @param email - the user's email
   * @param password - the user's password
   * */
  loginWithEmail(event: any, email: string, password: string) {
    // event will only be '' when we call it intentionally programmatically
      // if it is a normal event it means that this function is getting called from a form and we need to prevent the default behavior
      if (event !== '') {
        event.preventDefault();
      }

      let _this = this;

      // IMPORTANT NOTE: The subscription here has life beyond just login.  It also listens throughout the application for account list
      // changes and adjusts and routes the user as appropriate:

      this.authService.loginWithUsernamePassword(email, password).subscribe(
        (acctList) => {

          if (acctList && acctList.length > 0 && acctList[0].id) {

            this.authService.setAccount(acctList[0])
                .then(function() {
                  // Go to reset password page if the resetPasswordCompleted flag is false and we are not already on the /password-reset route
                  if(_this.authService.userData && _this.authService.userData.resetPasswordCompleted !== null && _this.authService.userData.resetPasswordCompleted !== undefined && _this.authService.userData.resetPasswordCompleted === false)
                    _this.router.navigate(["/password-reset"]);
                  else
                    _this.router.navigate(["/"])
                });

          }
        },
        (err: any) => {

          if(this.loginBtn) {
            // this.loginBtn.disabled = false;
            this.loginBtn.classList.remove("noclick");
            this.loginBtn.innerHTML = "Login";
          }

          if (err) {
            if ((err instanceof HttpErrorResponse) && (err.status === 403)) {
              LoggingService.showMessage("Your username or password is incorrect.", 'error', 7000);
            }
            else {
              LoggingService.showMessage(err.message, 'error', 7000);
            }
          }
          else {

            LoggingService.showMessage("An unknown error has occurred", 'error', 7000);
          }
        });
  }

  public disableAndWait(e) {
    this.loginBtn = e.target;
    // this.loginBtn.disabled = true;
    this.loginBtn.classList.add("noclick");
    this.loginBtn.innerHTML = "Please wait...";
  }

  public submitLogin(f, e, model) {
    console.log("Attempting login...");
    return f.form.valid && this.loginWithEmail(e, model.email, model.password)
  }
}
