
import {throwError as observableThrowError, Observable, BehaviorSubject, ReplaySubject, Subscription, EMPTY, combineLatest} from 'rxjs';
import { map, filter, catchError } from 'rxjs/operators'
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {DeviceService} from './device.service';
import {ErrorWithStatusCode, GeneralUtilService} from "../helpers/general-util.service";
import {ConfigService} from "../helpers/config.service";
import {IronHttpError, LoggingService} from "./logging.service";
import {Router} from "@angular/router";
import {SupportConstants} from "../../../support_constants";

export interface LoginResult {

  token: string;
  accounts: {
    id: string;
    name: string;
  }[];
}

export interface AuthorizeResult {

  token: string;
}

//*** IMPORTANT NOTE: There is a copy of this in IronServer!  Keep them both up to date!
export let LOGOUT_REASON = {
  FORBIDDEN_ACTION: "Forbidden action detected",
  ERROR_DURING_AUTH: "Error during auth event",
  ERROR_DURING_DATA: "Error getting account data",
  ACCOUNT_INACTIVED: "Account has been made inactive",
  NON_ADMIN: "Admin action attempted by non-admin",
  ERROR_DURING_SESSION_INITIALIZATION: "Error during session initialization",
  TIMEOUT: "Session timeout",
  USER_INITIATED: "User-initiated",
  USER_DISABLED: "User disabled",
  NO_ACCOUNTS: "User is not a member of any accounts",
  NO_LONGER_MEMBER: "User is no longer of member of account",
  CHANGED_PASSWORD: "User reset password."
};

export class UserData {
  id: string;
  name: string;
  email?: string;
  phone?: string;
  usernames?: string[];
  role?: string;
  extraPrivileges?: string[];
  enabled: boolean;
  resetPasswordCompleted?: boolean;
  accountId?: string;
}

export interface AccountData {
  id: string;
  enabled: boolean;
  uriEncodedId?: string;
  longName?: string;
  dispName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  logo?: string;
  theme?: string;
  firstContactName?: string;
  firstContactEmail?: string;
  firstContactPhone?: string;
  secondContactName?: string;
  secondContactEmail?: string;
  secondContactPhone?: string;
  users?: UserData[];
}

/** Service to directly handle all user/account authentication */
@Injectable()
export class AuthenticationService {

  /** reason for the user's most recent logout */
  public logoutReason: string;

  /** the current authenticated user's auth token */
  public authToken: string;

  /** the current authenticated user's auth token encoded for user in a uri */
  public authTokenEncoded: string;

  /** current user's settings and info */
  public userData: UserData;

  /** the account information that the user is using */
  public accountData: AccountData;

  public loginToken: string;

  public loginInProgressOrUncompleted: boolean = true;
  public authTokenUpdate: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  /** boolean that tracks whether or not the user is logged in */
  public isLoggedIn: boolean;

  public lastUpdate: number;

  public logoutInProgress: boolean = false;

  public concurrentAccessTrigger: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /** @ignore
   */
  constructor(public _router: Router, public http: HttpClient, public _deviceService: DeviceService) {

  }

  //Convenience method to return an HTTP request options object (since 99%) of our requests all need the same basic options.
  //NOTE: To remove the content type header (and not take the default), pass a null for the first argument.
  //ALSO NOTE: The standard headers can be overwritten by putting the same header names in the additionalHeaders object.
  public createRequestOptions(contentType: string = "application/json", additionalHeaders: {[key: string]: string | string[]} = Object.create(null)) {

      const headersSeed = {
        "token": this.authToken,
        "account-id": this.accountData.id,
        "last-update": this.lastUpdate.toString()
      };

      //*** DEVELOPER NOTE: If a no "'Access-Control-Allow-Origin' header is present on the requested resource" error is thrown by the browser, it
      //could be an incorrect error message.  The actual problem might be that you have included headers in your request that the server does not have
      //in its allowed headers list.

      //Add the content type unless it is specifically null:
      if (contentType !== null) {
        headersSeed["Content-Type"] = contentType;
      }

      //Add additional headers:
      //NOTE: This will overwrite any that had the same name from above:
      // for (let key in additionalHeaders) if (additionalHeaders.hasOwnProperty(key)) {
      //   options.headers = options.headers.append(key, additionalHeaders[key]);
      // }
      for (let key in additionalHeaders) if (additionalHeaders.hasOwnProperty(key)) {
        headersSeed[key] = additionalHeaders[key];
      }
      
      return {
        headers: new HttpHeaders(headersSeed),
        observe: "response" as "response"
      };
    }

  /** calls cloud opsserver and retrieves a token that we can use for authentication purposes
   * @public
   @returns {Observable}
   */
  private callLoginService(username: string, password: string): Observable<LoginResult> {

    // Check device info for browser that is logging in
    const body = Object.create(null);
    body['username'] = username;
    body['password'] = password;

    // Get a login token:
    const url = environment.webURLBase + 'login';

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response'
    };

    return this.http.post<LoginResult>(url, body, options)
      .pipe(
        map<HttpResponse<LoginResult>, LoginResult>(response => response.body),
        catchError((err) => {

          this.logout(LOGOUT_REASON.ERROR_DURING_AUTH);

          return observableThrowError(err);
        })
      );
  }

  /** login with username and password.
   * @param username - the user's email
   * @param password - the user's password
   */
  loginWithUsernamePassword(username: string, password: string): Observable<AccountData[]> {

    this.clearAllData();

    return this.callLoginService(username, password)
      .pipe(
        map((result: LoginResult) => {

          this.loginToken = result.token;

          if (!result.accounts || result.accounts.length === 0) {

            throw new Error("User is not a member of any accounts.");
          }

          let accts : AccountData[] = Array();
          result.accounts.forEach(function(result) {
            let acct : AccountData = Object();
            acct.id = result.id;
            acct.dispName = result.name;
            acct.theme = 'dark_gray';
            accts.push(acct);
          });

          return accts;
        })
      );

  }

  /** calls cloud opsserver and retrieves a account data
   * @public
   @returns {Promise}
   */
  private async callAccountDataService(): Promise<any> {

    if(!this.accountData.id || this.accountData.id === "")
      throw new Error("Account is not set. Cannot get account data.");

    // Get a account data after authorization:
    const url = environment.webURLBase + 'acctdata';

    let resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "token": this.authToken,
        "account-id": this.accountData.id
      },
      body: JSON.stringify({})
    });

    if(resp)
      return resp.json();

    return Promise.reject("AccountDataService provided no response.");
  }

  /**
   * Used to set the account id
   * @param accountID - String value
   * @param accountName - Display name of the account
   */
    public async setAccountData(acct : AccountData) {

      this.accountData = acct;
      this.accountData.uriEncodedId = encodeURIComponent(this.accountData.id);

      let response = await this.callAccountDataService();

      if(response) {
        // account id and display name are already available and don't need to be reset here
        this.accountData.longName = response.long_name;

        this.accountData.address1 = response.address1;
        if(response.address2)
          this.accountData.address2 = response.address2;
        this.accountData.city = response.city;
        this.accountData.state = response.state;
        this.accountData.zip = response.zip;
        this.accountData.country = response.country;

        this.accountData.firstContactName = response.primary_first + " " + response.primary_last;
        this.accountData.firstContactEmail = response.primary_email;
        this.accountData.firstContactPhone = response.primary_phone;

        if(response.secondary_first && response.secondary_last)
          this.accountData.secondContactName = response.secondary_first + " " + response.secondary_last;
        if(response.secondary_email)
          this.accountData.secondContactEmail = response.secondary_email;
        if(response.secondary_phone)
          this.accountData.secondContactPhone = response.secondary_phone;

        this.accountData.logo = 'default';
        if(response.logo && response.logo !== "") {
          this.accountData.logo = response.logo;
        }

        if(response.account_users) {
          this.accountData.users = [];
          for(let i = 0; i < response.account_users.length; i++) {
            let usrDat = new UserData();
            usrDat.id = response.account_users[i].id;
            usrDat.email = response.account_users[i].email;
            usrDat.name = response.account_users[i].name;
            usrDat.phone = response.account_users[i].phone;
            usrDat.usernames = response.account_users[i].usernames;
            usrDat.enabled = response.account_users[i].enabled;
            this.accountData.users.push(usrDat);
          }
        }

        if(response.user_info) {
          this.userData = new UserData();
          this.userData.id = response.user_info.id;
          this.userData.email = response.user_info.email;
          this.userData.name = response.user_info.name;
          this.userData.phone = response.user_info.phone;
          this.userData.role = response.user_info.role;
          this.userData.resetPasswordCompleted = response.user_info.reset_password_completed;
          this.userData.enabled = true;  // if the user has successfully logged in then it has to be enabled
        }
      }

      localStorage.setItem(GeneralUtilService.LocalStorage.account_id, this.accountData.id);
      localStorage.setItem(GeneralUtilService.LocalStorage.account_name, this.accountData.dispName);
    }


    /**
     * Used to set the authToken
     * @param authToken - String value
     */
    public setAuthToken(authToken: string, updateLocalStorage: boolean) {

      this.authToken = authToken;
      this.authTokenEncoded = (authToken) ? encodeURIComponent(this.authToken) : undefined;
      this.lastUpdate = (new Date()).getTime();

      //Notify listeners:
      this.authTokenUpdate.next(authToken);

      this.loginToken = undefined;

      // Put the token in localstorage for future use:
      if (updateLocalStorage) {
        localStorage.setItem(GeneralUtilService.LocalStorage.token, this.authToken);
      }
    }

    /** calls cloud opsserver and retrieves an authorization/session token
     * @public
     @returns {Observable}
     */
    public callAuthorizeService(account_id): Observable<AuthorizeResult> {

      // Check device info for browser that is logging in
      let body = this._deviceService.getDeviceInfo();
      body['isMobile'] = this._deviceService._isMobile;
      body['accountid'] = account_id;
      body['token'] = this.loginToken;

      // Get a login token:
      let url = environment.webURLBase + 'authorize';

      let options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response' as 'response'
      };

      return this.http.post<AuthorizeResult>(url, body, options)
        .pipe(
          map<HttpResponse<AuthorizeResult>, AuthorizeResult>(response => response.body),
          catchError((err) => {

            this.logout(LOGOUT_REASON.ERROR_DURING_AUTH);

            return observableThrowError(err);
          })
        );
    }

  /** given an account id, goes and sets all of the necessary variables everywhere so that the application reflects using the account ID
     * @public
     */
    public setAccount(acct: AccountData) {

      return this.callAuthorizeService(acct.id).toPromise()
        .then((authResult: AuthorizeResult) => {

            return this.setupAuthorizedUserSession(acct, authResult.token);
          },
        (err) => {

          console.error(err);
        });
    }

    public async setupAuthorizedUserSession(acct: AccountData, authToken: string): Promise<void> {

      try {

        this.setAuthToken(authToken, true);

        // Set account ID as shared service variable
        await this.setAccountData(acct);

        this.isLoggedIn = true;

        //Initialize the last update time to the current epoch timestamp, so we have a base value to use for caching:
        this.lastUpdate = (new Date()).getTime();
      }
      catch (err) {

        this.logout(LOGOUT_REASON.ERROR_DURING_SESSION_INITIALIZATION);

        throw err;
      }
    }


  /** this function is called to do a full logout of the entire system
     * @public
     */
    public logout(logoutReason: string): Observable<any> {

      //If the logout is already in progress, we can simply return:
      if (this.logoutInProgress) {

        return EMPTY;
      }

      this.logoutInProgress = true;

      //Store the logout reason so it is accessible by other components:
      this.logoutReason = logoutReason;

      let url = environment.webURLBase + 'logout';

      // NOTE: We must wait for it to complete before clearing data (some of the values that we clear are
      // used in the request)
      return this.http.post(url, {
          'isMobile': this._deviceService._isMobile,
          'reason': logoutReason
        }, this.createRequestOptions())
        .pipe(
          map(response => {
            this.finishLogout();
          }),
          catchError((err) => {
            console.error(err);
            this.finishLogout();

            return observableThrowError(err);
          })
        );
    }

    /** complete all logout functionality */
    private finishLogout() {

      // clear all data from the shared service that we don't want to stick around
      this.clearAllData();

      this.logoutInProgress = false;
    }

  public logoutAndRoute(logoutReason: string) {

    // make sure to close any active modal in case one is open at the time of logout and route user
    // We cannot reference the routing service here to avoid circular references
    //this.routingService.closeActiveModal(RoutingService.Routes.LOGOUT);
    let modalBtns = document.getElementsByClassName('hidden-btn-dismiss');
    if (modalBtns) {

      for (let i = 0; i < modalBtns.length; i++) {
        let el: any = modalBtns[i];
        el.click();
      }
    }

    this._router.navigate(["/login"]);
    this.logout(logoutReason).subscribe(() => {
    });

  }

  /*
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   *                                                                                             *
   *                          Clearing Functions                                                 *
   *                                                                                             *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   */

    /** This function is called to reset values for a new login.. important so that once a user chooses to logout, they can then go and re login if they need
     */
    public resetValuesForNewLogin() {

      this.loginInProgressOrUncompleted = true;
      this.isLoggedIn = false;
      this.setAuthToken(undefined, false);

      //Only update these values if they are not already undefined:
      //NOTE: This prevents our subjects from firing more than they need to.
      if (this.userData) {
        this.userData = undefined;
      }
      if (this.accountData) {
        this.accountData = { "id": null, "enabled": false, "theme": "dark_gray" }; // If we make this undefined, we get many HTML errors trying to access the account "theme"
      }
      if (this.userData) {
        this.userData = undefined;
      }
    }

    /** clears local storage fully of any of the data that we set originally
     */
    public clearLocalStorage(): void {

      // make sure that local values are cleared
      localStorage.setItem(GeneralUtilService.LocalStorage.email, '');
      localStorage.setItem(GeneralUtilService.LocalStorage.account_id, '');
      localStorage.setItem(GeneralUtilService.LocalStorage.account_name, '');
      localStorage.setItem(GeneralUtilService.LocalStorage.token, '');
    }

    /** clears other important values
     * @public
     */
    public clearAllData() {

      //It's important that we clear local storage first because resetValuesForNewLogin can trigger subscribers to user data to run and if the local storage
      //isn't clear already, they may think the user is still logged in.
      this.clearLocalStorage();
      this.resetValuesForNewLogin();
    }

    /** this function double checks that the user is an admin
     @public
     */
    public makeSureUserIsAdmin() {

      if (!this.userData || (this.userData.role !== 'admin')) {
        this.logout(LOGOUT_REASON.NON_ADMIN);
      }
    }

  /** this function returns true if the user is an admin
   @public
   */
  public userIsAdmin() : boolean {

    return (this.userData && this.userData.role === 'admin')
  }

  public async updateOwnPassword(newPassword: string) {

    let _this = this;

    //Reset this flag so that the app knows login is no longer in progress:
    this.loginInProgressOrUncompleted = false;

    const url = environment.webURLBase + 'uop';

    return await this.http.post(url, {
      id: this.userData.id,
      passwd: newPassword
    }, this.createRequestOptions()).toPromise()
        .then(function(response) {

          if(response.body && response.body['saved']) {
            LoggingService.showMessage('Your password has been reset.  Please log in with new password.', 'success', 'default');

            //  Force logout
            _this.logoutAndRoute(LOGOUT_REASON.CHANGED_PASSWORD);

            return response.body['saved'];
          }
        })
            .catch((err) => {
              if(!err || !err.error || !err.error.server_error ||!err.error.server_error.message)
                LoggingService.showMessage('There was an error trying to reset your password.', 'error', 'default');
              else
                LoggingService.showMessage(err.error.server_error.message, 'error', 'default');

              //  Force logout
              _this.logoutAndRoute(LOGOUT_REASON.CHANGED_PASSWORD);
        });
  }

  /*
  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  *                                                                                             *
  *                          Error Handling Functions                                           *
  *                                                                                             *
  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  */

      public deriveFinalErrorAndHandleIt(err: any): ErrorWithStatusCode {

        let finalErr: ErrorWithStatusCode;

        // Normalize all other errors to have a message property:
        let errMsg: string;

        // If the error is already an instance of Error, then just use it:
        if (err instanceof ErrorWithStatusCode) {
          finalErr = err;
        }
        else if (err instanceof Error) {
          finalErr = new ErrorWithStatusCode(err);
        }

        // An HTTP client-side or network error occurred. Handle it accordingly.
        else if (err instanceof HttpErrorResponse) {

          const ironHttpError: IronHttpError = new IronHttpError(err);

          //For "Forbidden" errors, immediately log the error and force the user out:
          if (ironHttpError.status === 403) {

            console.error(ironHttpError);
            LoggingService.showMessage(ironHttpError.message, 'error', 5000);
            this.logoutAndRoute(LOGOUT_REASON.FORBIDDEN_ACTION);
          }

          finalErr = ironHttpError;
        }
        else if (err instanceof IronHttpError) {

          //For "Forbidden" errors, immediately log the error and force the user out:
          if (err.status === 403) {

            console.error(err);
            LoggingService.showMessage(err.message, 'error', 5000);
            this.logoutAndRoute(LOGOUT_REASON.FORBIDDEN_ACTION);
          }

          finalErr = err;
        }

        // Create a new error from the message in a message property or just by converting the error to a string:
        else {
          if (err.message) {
            errMsg = err.message;
          }
          else {
            errMsg = err.toString();
          }

          finalErr = new ErrorWithStatusCode(new Error(errMsg));
        }

        return finalErr;
      }

      /** generic error handler
       * @public
       */
      public handleError(err: any, showMessage: boolean = false, messageType: string = "error", messageTime: number = 5000) {

        //LoggingService.docxonomyLogging(50, {operation: 'handleError', subcomponent: 'authentication.service'}, err, false, false);

        const finalErr: ErrorWithStatusCode = this.deriveFinalErrorAndHandleIt(err);

        console.error(finalErr);

        if (showMessage) {
          LoggingService.showMessage(finalErr.message, messageType, messageTime);
        }

        return false;
      }
  }
