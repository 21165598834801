// The list of which env maps to which file can be found in `.angular-cli.json`.
/** @ignore */

const proto = "https";
const host = "delta.docxonomy.cloud";

// const proto = "http";
// const host = "localhost:8080";

export const environment = {
  production: true,
  envName: "CloudOps",
  webURLBase: proto + "://" + host + "/ops/",
  admURLBase: proto + "://" + host + "/adm/"
};
