import {Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {DxValidatorComponent} from "devextreme-angular";

/** A class for validating password values */
export class Password {

  value: string = "";
  isValid: boolean = false;

  setValidPassword(value: string) {
    this.value = value;
    this.isValid = true;
  }
}

@Component({
  selector: 'app-password-input-validator',
  templateUrl: './password-input-validator.component.html',
  styleUrls: ['./password-input-validator.component.css']
})
export class PasswordInputValidatorComponent implements OnChanges {

  /** N.B. - This component relies on the use of Dev Extreme validators, thus, this component's selector should be placed inside of a
   * Dev Extreme validation group!
   */

  /** the title to display for the field set */
  @Input('Title') title?: string = "Password"; //default if omitted by host component;

  /** the help text to show */
  @Input('HelpText') helpText?: string;

  /** the validation message to show */
  @Input('FieldValidationMessage') fieldValidationMessage: string = "A password must be provided"; //default if omitted by host component

  /** the minimum password length */
  @Input('MinLength') minPWLength?: number = 1; //default if omitted by host component

  /** the validated password value - both an input and an output */
  @Input('ValidatedPassword') validatedPassword: string;
  @Output() ValidatedPasswordChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('confirmValidator') confirmValidator: DxValidatorComponent;

  /** password objects to hold password values and validity */
  passwordOne: Password = new Password();
  passwordTwo: Password = new Password();

  constructor() {

  }

  /** @ignore */
  ngOnChanges(changes) {

    //If the validatedPassword input changes, (re)initialize the two password fields as such:
    //NOTE: We don't (re)initialize the two password fields if the value of the change is null, because that happens
    //when the passwords are not valid (which could be while by the user is entering the values and they don't match). We also
    // only initialize the passwords as valid if there was no previous validatedPassword or if this is the first onChanges call.  Thus,
    // when the input validated password value has a real value that represents an existing, formerly validated password that we want to initialize on this
    // component, then we set the two password fields.  This would only be the case when a new (previously set password) was provided by the host component
    if ((changes.validatedPassword) && (changes.validatedPassword.currentValue) && ((!changes.validatedPassword.previousValue) || (changes.firstChange))) {

      this.passwordOne.setValidPassword(changes.validatedPassword.currentValue);
      this.passwordTwo.setValidPassword(changes.validatedPassword.currentValue);
    }
  }

  //Return the first password for comparison by DevExtreme.
  // IMPORTANT NOTE: We've purposely written this function in "arrow" form so it doesn't lose
  // its definition of "this" when called by DevExtreme!
  getPasswordOne = () => {
    return this.passwordOne.value;
  };

  //When dev extreme fires the onValidation event for one of the password fields, update the isValid flag on the
  //associated password object and then check both password objects - if both are valid, we update our validatedPassword
  // value and fire the output event to tell the host/parent component what the latest valid password is:
  validationEventTriggered(event: any, updatedPassword: Password, isFirstPassword: boolean) {

    //Set the isValid flag on the relevant password object based on the validation event results from dev extreme:
    updatedPassword.isValid = (event && event.isValid);

    //If the event is from the first password field changing and retriggering validation, then trigger validation on the
    //second one too - to ensure the user knows it may no longer validate, but more importantly to trigger the statements
    //below to update or clear the validatedPassword output as appropriate:
    if (isFirstPassword) {
      this.confirmValidator.instance.validate();
    }

    //If the password is indeed valid, then store the password in the validated password variable, making it available as output:
    //NOTE: We need to check both passwords to see if they are valid because they each have different rules applied (see comments
    //in template)
    if (this.passwordOne.isValid && this.passwordTwo.isValid) {

      this.validatedPassword = this.passwordOne.value.trim();
    }
    else { //clear the value:

      this.validatedPassword = null;
    }

    //Emit the updated password value:
    this.ValidatedPasswordChange.emit(this.validatedPassword);
  }
}
