import { Component, OnInit , Input , Output , EventEmitter} from '@angular/core';

/** subcomponent that handles displaying an icon from multiple different types of libraries */
@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css']
})
export class IconComponent implements OnInit {
  /** a title for the icon */
  @Input('Title') title: string;

  /** icon name within the library given */
  @Input('IconName') icon_name: string;

  /** icon library to use */
  @Input('IconType') icon_type: string;

  /** classes that should get added to the icon */
  @Input('Classes') classes: string;

  /** an id that should be provided to the icon */
  @Input('Id') id: string;


  /** emits an event anytime the icon is clicked */
  @Output('onIconClicked') iconClicked = new EventEmitter<any>();

  /** @ignore */
  constructor() {

  }

  /** @ignore */
  ngOnInit() {
  }

  /** handles when an icon gets clicked on
   * @param event - the click event from the icon
   * */
  action(event): void {
    this.iconClicked.emit(event);
  }
}

