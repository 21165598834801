<div class="container-fluid loginAndAccountSelection-container">

  <div class="row loginAndAccountSelectionImg-container" style="width: 600px; position: relative; left: 50%; margin-left: -300px;">
    <img src="../assets/images/logo@2x.png" class="loginAndAccountSelectionImg" style="position: relative; top: 50px;" />
    <div style="font-size: 36pt; color: #C0C0C0; position: relative; left: 120px;">Support</div>
  </div>
  <div class="row loginAndAccountSelectionForm-container {{windowRef.innerWidth >= 767 ? 'large-size' : 'smaller-size'}}" *ngIf="!authService.isLoggedIn" [@slideLeftFadeOut]="animationString">
    <div *ngIf="!forgetPassword">
      <form name="form" method="POST" (ngSubmit)="submitLogin(f, $event, model)" #f="ngForm"
            novalidate class="loginAndAccountSelectionForm fade-in">
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
          <div class="input-group">
            <span class="input-group-addon"><i class="fas fa-envelope loginAndAccountSelectionIcon"></i></span>
            <input type="text" class="form-control login-input" name="email" [(ngModel)]="model.email" #email="ngModel" placeholder="Email" required/>
          </div>

          <div *ngIf="f.submitted && !email.valid" class="help-block">Email is required</div>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
          <div class="input-group">
            <span class="input-group-addon"><i class="fas fa-asterisk loginAndAccountSelectionIcon"></i></span>
            <input type="password" class="form-control login-input" name="password" [(ngModel)]="model.password" #password="ngModel" placeholder="Password" required/>
          </div>
          <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
        </div>

        <div class="form-group loginAndAccountSelectionLoginBtn">
          <button class="btn btn-default docx-bg-red login-btn" (click)="disableAndWait($event)">Login</button>
        </div>
      </form>
    </div>

  </div>
</div>

<app-footer [HelpInfo]="'LoginPage'"></app-footer>
