import { Injectable } from '@angular/core';
import {Title} from "@angular/platform-browser";

/** an extension of the normal angular title service
 */
@Injectable()
export class CustomTitleService extends Title {

  /** the default title for the application */
  public defaultTitle: string = 'Docxonomy';

  /**
   * @ignore
   */
  constructor() {
    super(document);
  }

  /** sets a custom title to the window title.
   * @param title - the title that should be added
   * @param useDefault - whether or not to use the default title
   */
  public setCustomTitle(title: string, useDefault: boolean) {
    let finalTitle: string = '';
    if(useDefault && title) {
      finalTitle = this.defaultTitle + ' - ' + title;
    }
    else if(useDefault) {
      finalTitle = this.defaultTitle;
    }
    else {
      finalTitle = title;
    }
    this.setTitle(finalTitle);
  }

  /** sets a custom title to the window title that flashes on the tab bar
   * @param flashingTitle - the title that should be added
   * @param duration - duration of the flashing
   */
  public createFlashingTitle(flashingTitle: string, duration: number) {
    window['flashingInterval'] = setInterval(()=> {
      //get the default title for the application
      let currTitle = this.getTitle();

      //if the current title is the default title then set the current title to be the opposite one
      // otherwise just set the default title
      if(currTitle === this.defaultTitle) {
        this.setTitle(flashingTitle);
      }
      else {
        this.setTitle(this.defaultTitle);
      }
    }, duration);
  }

  /** clears the flashing title and then sets the default title
   */
  public clearFlashingTitle() {
    if(window['flashingInterval'] ) {
      clearInterval(window['flashingInterval'] );

      this.setTitle(this.defaultTitle);
    }
  }
}
