
<div *ngIf="title;else elseblock" class="icon-div">
  <i class="{{icon_type}} fa-{{icon_name}} {{classes}} icon-default-class" (click)="action($event)" *ngIf="icon_type === 'fal' || icon_type === 'fab' || icon_type === 'fas' || icon_type === 'far' || icon_type === 'fa'" [id]="id ? id:''"></i>

  <i class="mi mi-{{icon_name}} {{classes}} icon-default-class" (click)="action($event)" *ngIf="icon_type === 'mi'" [id]="id ? id:''"></i>

  <i class="glyphicon glyphicon-{{icon_name}} {{classes}} icon-default-class" (click)="action($event)" *ngIf="icon_type === 'bs'" [id]="id?id:''"></i>
</div>
<ng-template #elseblock>
  <div  class="icon-div">
    <i class="{{icon_type}} fa-{{icon_name}} {{classes}} icon-default-class" (click)="action($event)" *ngIf="icon_type === 'fab' || icon_type === 'fas' || icon_type === 'fal' || icon_type === 'far' || icon_type === 'fa'" [id]="id ? id:''"></i>

    <i class="mi mi-{{icon_name}} {{classes}} icon-default-class" (click)="action($event)" *ngIf="icon_type === 'mi'" [id]="id ? id:''"></i>

    <i class="glyphicon glyphicon-{{icon_name}} {{classes}} icon-default-class" (click)="action($event)" *ngIf="icon_type === 'bs'" [id]="id?id:''"></i>
  </div>
</ng-template>

