import { Pipe, PipeTransform } from '@angular/core';

/** given a string and a number, truncate the string by that amount. */
@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(string: string, character_amount: number, suffix: string): any {
    if(string) {
      let difference = character_amount - string.length;
      if(difference < 0) {
        if(Math.abs(difference) > suffix.length) {
          string = string.substring(0,character_amount) + suffix;
        }
        else {
          string = string.substring(0,(character_amount) - (difference - suffix.length)) + suffix;
        }
      }
      return string;
    }
    else {
      return '';
    }
  }

}
