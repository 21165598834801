import {environment} from '../../../environments/environment';
import * as supportconstants from '../../../support_constants.json';
import {SupportConstants} from '../../../support_constants';
import {Observable} from "rxjs";
import {GeneralUtilService} from "./general-util.service";

/** Service with functions relating to configurations throughout platinum */
export class ConfigService {

  //Load our static configs into memory:
  static loadConstants(): SupportConstants {
    const config = <SupportConstants>(<any>supportconstants).default;
    config.urls.base = environment.webURLBase;
    return config;
  }
  public static spConstants: SupportConstants = ConfigService.loadConstants();

  /** Services throughout the application. These services are broad parts of the application that can be turned on/off by specific plans. */
  static Services = {
    DEFAULT: 'default'
  };

  /** Different memory units */
  static memoryUnits = ["Bytes", "KB", "MB", "GB", "TB"];

  /** helper function to format bytes
   */
  static formatBytes(num, display) {
    return ConfigService.formatBytesToSpecificLevel(num, 0, display);
  }

  /** helper function to format bytes to a specific level
   */
  static formatBytesToSpecificLevel(num, level, display) {
    if (num < 1024 || level > ConfigService.memoryUnits.length - 1) {
      return display ? num + " " + ConfigService.memoryUnits[level] : num;
    } else {
      return ConfigService.formatBytesToSpecificLevel(num / 1024, level + 1, display);
    }
  }

  /** helper function to format a specific level of bytes to just bytes
   */
  static formatSpecificLevelToBytes(num, level, display) {
    if (level === 0) {
        return display ? num + " " + ConfigService.memoryUnits[level] : num;
    } else {
      return ConfigService.formatSpecificLevelToBytes(num * 1024, level - 1, display);
    }
  }

  /** helper function to format mb to bytes
   */
  static mBToBytes(num) {
    return ConfigService.formatSpecificLevelToBytes(num,2, false);
  }

  /** helper function to replace a placeholder within the platinum constants with an icon from platinum constants
   */
  static checkTextForIconReplacementAndReplaceIcon(text: string): string {
    //this function takes in a text string and looks for any replacements it needs to make for icons according to the format below
    // {{ICONNAME_icon}}
    // in this case ICONNAME is the name of the icon within the platinumConfig.icons node
    return text.replace(/{{([a-z]+)_icon}}/gmi,(fullmatch,whichIcon)=> {
      return "<i class='" + this.spConstants.icons[whichIcon].type + " fa-" + this.spConstants.icons[whichIcon].icon + "'></i>"
    });
  }

}
