import {forwardRef, Inject, Injectable, Injector} from '@angular/core';
import {AuthenticationService, LOGOUT_REASON} from "./authentication.service";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {LoggingService} from "./logging.service";

/** simple http interceptor to be able to logout the user if we receive a code from the server (403 that there was an unauthorized request)
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  /** @ignore */
  constructor(@Inject(forwardRef(() => Injector)) private injector: Injector) {
  }

  /** an interceptor for all errors from http calls */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {

            LoggingService.docxonomyLogging(79, {
              operation: 'TokenInterceptor. intercept',
              subcomponent: 'token-interceptor.service'
            }, err, false, false);

            // if the server tells us that there was a forbidden call then logout the user
            this.injector.get(AuthenticationService).logoutAndRoute(LOGOUT_REASON.FORBIDDEN_ACTION);
          }
        }
      })
    );
  }
}

