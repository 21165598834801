/* global */
import { ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import * as $ from 'jquery';
import 'mark.js';

/* global */

/* imports */
// todo look at how often each of these are used and if they can be replaced with simpler quicker elements
// todo they have a negative impact on bootstrapping the app
import {
  DxTreeListModule,
  DxProgressBarModule,
  DxChartModule,
  DxTextBoxModule,
  DxDataGridModule,
  DxAutocompleteModule,
  DxTagBoxModule,
  DxSelectBoxModule,
  DxValidationSummaryModule,
  DxValidatorModule,
  DxValidationGroupModule,
  DxLookupModule,
  DxPieChartModule,
  DxRangeSelectorModule,
  DxTileViewModule,
  DxCheckBoxModule,
  DxNumberBoxModule,
  DxSwitchModule,
  DxDateBoxModule,
  DxSliderModule,
  DxRadioGroupModule,
  DxMapModule,
  DxTextAreaModule,
  DxButtonModule,
  DxFormModule,
  DxListModule,
  DxPivotGridModule,
  DxContextMenuModule,
  DxAccordionModule
} from 'devextreme-angular';
import { HttpClientModule } from '@angular/common/http';
import { routing} from './app.routing';

import { FormsModule } from '@angular/forms';
import { BrowserModule , Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarModule } from 'ng-sidebar';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { AngularCropperjsModule } from 'angular-cropperjs';

/* imports */

/* providers */
import { AuthenticationService } from './_services/functionality/authentication.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceService } from './_services/functionality/device.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './_services/functionality/token-interceptor.service';
import { GlobalErrorHandlerService } from './_services/functionality/global-error-handler.service';
import { TimeoutService } from './_services/functionality/timeout.service';
import { CustomTitleService } from './_services/functionality/custom-title-service.service';
/* providers */

/* declarations */
import { AppComponent } from './app.component';
import { IconComponent } from './_subcomponents/icon/icon.component';
import { HeaderComponent } from './_subcomponents/header/header.component';
import { SubnavComponent } from './_subcomponents/subnav/subnav.component';
import { FooterComponent } from './_subcomponents/footer/footer.component';
import { ModalComponent } from './_subcomponents/modal/modal.component';
import { LoadingComponent } from './_subcomponents/loading/loading.component';
import { PanelComponent } from './_subcomponents/panel/panel.component';

import { LandingComponent } from './landing/landing.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { ProfileComponent } from './profile/profile.component';
import { AdministrationComponent } from './administration/administration.component';

import { LoginComponent } from './login/login.component';
import { PasswordResetComponent } from './_subcomponents/password-reset/password-reset.component';
import { PasswordInputValidatorComponent } from './_subcomponents/password-input-validator/password-input-validator.component';

import { DatePipe } from '@angular/common';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SafehtmlPipe } from './pipes/safehtml.pipe';

import { AuthorizedHeaderComponent } from './_subcomponents/header/authorized-header/authorized-header.component';

import { AuthGuard } from './_guards/auth.guard';
import { AdminGuard } from './_guards/admin.guard';

/* declarations */

// @ts-ignore
@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    routing,
    DxTreeListModule,
    DxDataGridModule,
    DxAutocompleteModule,
    DxTagBoxModule,
    DxValidationSummaryModule,
    DxValidatorModule,
    DxValidationGroupModule,
    DxSliderModule,
    DxDateBoxModule,
    DxSwitchModule,
    DxRangeSelectorModule,
    DxButtonModule,

    // devextreme modules that probably should be changed to normal elements
    DxTextBoxModule,
    DxTextAreaModule,
    DxNumberBoxModule,
    DxSelectBoxModule,
    DxPieChartModule, // replace with charts js
    DxChartModule, // replace with charts js
    DxLookupModule,
    DxRadioGroupModule,
    DxMapModule,
    DxPivotGridModule,

    // devextreme modules that can definitely be changed to normal elements
    DxTileViewModule,
    DxProgressBarModule,
    DxCheckBoxModule,
    DxFormModule,
    DxListModule,
    DxContextMenuModule,
    DxAccordionModule,

    SidebarModule.forRoot(),
    LazyLoadImageModule,
    AngularCropperjsModule,
    BrowserAnimationsModule
  ],
  declarations: [
    AppComponent,
    SafePipe,
    SafehtmlPipe,
    LandingComponent,
    DocumentationComponent,
    DownloadsComponent,
    ProfileComponent,
    AdministrationComponent,
    LoginComponent,
    PasswordResetComponent,
    PasswordInputValidatorComponent,
    IconComponent,
    HeaderComponent,
    SubnavComponent,
    FooterComponent,
    LoadingComponent,
    PanelComponent,
    ModalComponent,
    AuthorizedHeaderComponent,
    TruncatePipe
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthenticationService,
    DeviceDetectorService,
    DeviceService,
    TimeoutService,
    CustomTitleService,
    Title,
    AuthGuard,
    AdminGuard
  ],
  bootstrap: [AppComponent]
})


export class AppModule {
}

