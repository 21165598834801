<h4 class="form-title">{{title}}</h4>
<div class="hint-icon" *ngIf="helpText">
  <app-help-inline-info [HelpText]="helpText"></app-help-inline-info>
</div>
<div class="dx-fieldset docx-fieldset">
  <div class="dx-field">
    <div>Password: </div>
    <div class="dx-field-value docx-fieldvalue">
      <dx-text-box mode="password" placeholder="Password" [(value)]="passwordOne.value" valueChangeEvent="keyup">
        <dx-validator (onValidated)="validationEventTriggered($event, passwordOne, true)">
          <dxi-validation-rule type="required" [message]="fieldValidationMessage"></dxi-validation-rule>
          <dxi-validation-rule type="stringLength" [min]=minPWLength [message]="fieldValidationMessage"></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </div>
  </div>
  <div class="dx-field">
    <div>Confirm Password: </div>
    <div class="dx-field-value docx-fieldvalue">
      <dx-text-box mode="password" placeholder="Confirm Password" [(value)]="passwordTwo.value" valueChangeEvent="keyup">
        <dx-validator #confirmValidator (onValidated)="validationEventTriggered($event, passwordTwo, false)">
<!-- We have commented out these validation rules so as not to be an annoyance to the user.  As long as this password field matches the other
password field, we know all of the validation rules have been met, because the other one has the required and size rules applied -->
<!--          <dxi-validation-rule type="required" [message]="fieldValidationMessage"></dxi-validation-rule>-->
<!--          <dxi-validation-rule type="stringLength" [min]=minPWLength [message]="fieldValidationMessage"></dxi-validation-rule>-->
          <dxi-validation-rule type="compare" [comparisonTarget]="getPasswordOne" message="Password and Confirm Password do not match"></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </div>
  </div>
</div>

