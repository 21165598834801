<footer class="footer">
  <div class="container loginAndAccountSelection-footer-container">
    <ul class="footer-items">

      <li class="not-sm">
        <a href="https://www.docxonomy.com" target="_blank" class="text-muted docx-font-thin">&copy; Docxonomy {{currentYear}}</a>
      </li>

    </ul>
  </div>
</footer>
