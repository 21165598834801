import { Component, Input, OnInit } from '@angular/core';
import {ConfigService} from '../../_services/helpers/config.service';
import {AuthenticationService} from "../../_services/functionality/authentication.service";

/** subcomponent to encapsulate the footer of the application.  */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  /** what the current year is. for the copyright message */
  currentYear: string;

  ConfigService = ConfigService;

  /** constants entry that should be displayed when the help icon is clicked */
  @Input('HelpInfo') HelpInfo: string | null;

  /** local instance of the js window property to be accessed within the template */
  windowRef: Window;

  /** the url to be used to share docxonomy with the world. */
  main_site_share_url: string;

  /** the description to be used when sharing . */
  shareDescription: string;

  /** @ignore */
  constructor(public authService: AuthenticationService) {

    this.windowRef = window;
  }

  /** handles opening the help center widget */
  openHelpWidget(): void {
    // TODO: Do something here...
  }

  /** @ignore */
  ngOnInit() {
    this.currentYear = new Date().getFullYear().toString();
  }

}
