<div class="container-fluid docs-container docx-scrollbar">
    <div *ngIf="!availableDocuments || availableDocuments.length === 0" class="none-available">No documentation is available at this time.</div>
    <div *ngIf="availableDocuments && availableDocuments.length > 0" id="menuTreeContainer">
        <dx-tree-list id="menuTree"
                      [dataSource]="availableDocuments"
                      [allowColumnResizing]="true"
                      [rootValue]="''"
                      [showColumnHeaders]="false">

            <dxi-column dataField="title" caption="" dataType="string" cellTemplate="menuTemplate">
                <div *dxTemplate="let row of 'menuTemplate'">
                    <div *ngIf="row.data.type === 'category'" [ngClass]="'doc-menu-' + row.data.type">{{row.data.title}}</div>
                    <div *ngIf="row.data.type === 'external'" [ngClass]="'doc-menu-' + row.data.type"><a [href]="docsUrls[row.data.id]" target="_blank" class="ext-link">{{row.data.title}}</a>&nbsp;&nbsp;&nbsp;<a [href]="docsUrls[row.data.id]" target="_blank" style="color: black;"><app-icon [IconName]="ConfigService.spConstants.icons.extlink.icon" [IconType]="ConfigService.spConstants.icons.extlink.type"></app-icon></a></div>
                    <div *ngIf="row.data.type === 'document'" [ngClass]="'doc-menu-' + row.data.type" (click)="updateHtml(row.data.id)">{{row.data.title}}<a [href]="docsUrls[row.data.id]" target="_blank" class="doc-icon-right"><app-icon [IconName]="ConfigService.spConstants.icons.print.icon" [IconType]="ConfigService.spConstants.icons.print.type"></app-icon></a></div>
                    <div *ngIf="row.data.type === 'section' || row.data.type === 'subsection'" [ngClass]="'doc-menu-' + row.data.type" (click)="updateHtmlAndScroll(row.data.docId, row.data.anchor)">{{row.data.title}}</div>
                </div>
            </dxi-column>

        </dx-tree-list>
    </div>
    <div id="verticalRule"></div>
    <div *ngIf="availableDocuments && availableDocuments.length > 0" id="documentFrameContainer">
        <span *ngIf="!docHtml">Select a document from the menu.</span>
        <div *ngIf="docHtml" id="documentFrame" name="documentFrame" [innerHtml]="docHtml"></div>
    </div>
</div>