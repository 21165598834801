import {Component, HostListener, OnInit} from '@angular/core';
import {DatePipe, TitleCasePipe} from "@angular/common";
import {ConfigService} from '../_services/helpers/config.service';
import {SupportConstants} from '../../support_constants';
import {LoggingService} from '../_services/functionality/logging.service';

import {AuthenticationService, LOGOUT_REASON} from "../_services/functionality/authentication.service";
import {environment} from "../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpResponse} from '@angular/common/http';
import {throwError as observableThrowError} from "rxjs/internal/observable/throwError";
import {Router} from "@angular/router";

/** declares the function download from external library */
declare function download(file, name, type): any;

export interface DownloadResult {
  file: File;
  name: string;
}

/** Main search page. handles before search, after search is done, and all search results. */
@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css'],
  providers: [TitleCasePipe, DatePipe]
})

export class DownloadsComponent implements OnInit {

  ConfigService = ConfigService;

  /** local instance of the platinum constants to be used in the template */
  constants: SupportConstants;

  /** local instance of the js object window to be used in the template */
  windowRef: Window;

  baseUrl: string;

  availableDownloads: any;
  downloadCategories: string[];

  downloadFilter: string;

  /** @ignore */
  constructor(public authService: AuthenticationService, public router: Router, public http: HttpClient) {

    this.constants = ConfigService.spConstants;
    this.windowRef = window;
    this.baseUrl = environment.webURLBase;
    this.availableDownloads = {};
    this.downloadCategories = [];
    this.downloadFilter = "licenses";
  }


  /** @ignore */
  async ngOnInit() {

    let result = await this.callDownloadsInfoService();
    if (result.downloads) {
      this.availableDownloads = result.downloads;
      this.downloadCategories = Object.keys(this.availableDownloads).filter(function (cat) {
        return (cat !== 'Licenses' && cat !== 'Packages');
      });
    } else {
      console.warn("No downloads are currently available.");
    }
  }

  public encodeToUri(val: string): string {
    return encodeURIComponent(val);
  }

  private callDownloadsInfoService(): Promise<any> {

    if(!this.authService.accountData.id || this.authService.accountData.id === "")
      throw new Error("Account is not set. Cannot get download data.");

    // Get a account data after authorization:
    const url = environment.webURLBase + 'downloads';

    return this.http.get<any>(url, this.authService.createRequestOptions())
        .pipe(
            map<HttpResponse<any>, any>(response => response.body),
            catchError((err) => {

              return observableThrowError(err);
            })
        ).toPromise();
  }
}
