<div class="container-fluid docx-container-subnav docx-scrollbar">
  <app-subnav>
    <div left>
      <ul class="nav navbar-nav">
        <li [ngClass]="{'open':adminFilter === 'accounts'}">
          <a class="clickable pageable" (click)="adminFilter = 'accounts'" id="acctsBtn">
            <span *ngIf="windowRef.innerWidth >= 996">Accounts</span>
          </a>
        </li>

        <li [ngClass]="{'open':adminFilter === 'users'}">
          <a class="clickable pageable" (click)="adminFilter = 'users'" id="usrsBtn">
            <span *ngIf="windowRef.innerWidth >= 996">Users</span>
          </a>
        </li>

        <li [ngClass]="{'open':adminFilter === 'licences'}">
          <a class="clickable pageable" (click)="adminFilter = 'licenses'" id="licsBtn">
            <span *ngIf="windowRef.innerWidth >= 996">Licenses</span>
          </a>
        </li>

        <li [ngClass]="{'open':adminFilter === 'announcements'}">
          <a class="clickable pageable" (click)="adminFilter = 'announcements'" id="annsBtn">
            <span *ngIf="windowRef.innerWidth >= 996">Announcements</span>
          </a>
        </li>

        <li [ngClass]="{'open':adminFilter === 'documents'}">
          <a class="clickable pageable" (click)="adminFilter = 'documents'" id="docsBtn">
            <span *ngIf="windowRef.innerWidth >= 996">Documents</span>
          </a>
        </li>

        <li [ngClass]="{'open':adminFilter === 'downloads'}">
          <a class="clickable pageable" (click)="adminFilter = 'downloads'" id="dldsBtn">
            <span *ngIf="windowRef.innerWidth >= 996">Downloads</span>
          </a>
        </li>

      </ul>
    </div>
    <div right>

    </div>
  </app-subnav>
  <br />

  <div *ngIf="adminFilter === 'accounts' && (!allAccounts || allAccounts.length === 0)" class="none-available">Account data loading.</div>
  <!-- <app-panel *ngIf="adminFilter === 'accounts' && allAccounts && allAccounts.length > 0" [Title]="'All Accounts'" [MaximizeControls]="false" class="download-category-panel">
    <div id="accounts-div" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne"> -->
      <div *ngIf="adminFilter === 'accounts' && allAccounts && allAccounts.length > 0" class="admin-data-container">
          <dx-data-grid
                  [dataSource]="allAccounts"
                  [masterDetail]="{ enabled: false }"
                  [showBorders]="true"
                  (onInitNewRow)="startAcctCreate($event)"
                  (onEditingStart)="startAcctUpdate($event)"
                  (onEditCanceled)="endAcctCreateOrUpdate($event)"
                  (onSaving)="sendAcctUpdate($event)"
                  (onSaved)="endAcctCreateOrUpdate($event)"
          >
            <dxo-editing
                    mode="form"
                    [allowUpdating]="true"
                    [allowDeleting]="false"
                    [allowAdding]="true"
            >
              <dxo-form [customizeItem]="changeAcctFormProps">
                <dxi-item itemType="group">
                  <dxi-item dataField="longName" [isRequired]="true"></dxi-item>
                  <dxi-item dataField="dispName" [isRequired]="true"></dxi-item>
                </dxi-item><br />
                <dxi-item itemType="group">
                  <dxi-item dataField="enabled" editorType="dxSwitch"></dxi-item>
                  <dxi-item dataField="id" [isRequired]="true"></dxi-item>
                  <!-- trying to disable/enable this item by binding to [disabled] or using [editorOptions] failed
                       in the end the customizeItem method was used, but I can find no reason that it should
                       be required to be this way... except that it works and the other ways didn't -->
                </dxi-item><br />
                <dxi-item itemType="group">
                  <dxi-item itemType="group" caption="Primary Contact">
                    <dxi-item dataField="firstContactName" [isRequired]="true"></dxi-item>
                    <dxi-item dataField="firstContactEmail">
                      <dxi-validation-rule
                              type="required"
                              message="An email address must be provided for the first contact.">
                      </dxi-validation-rule>
                      <dxi-validation-rule
                              type="email"
                              message="First contact email is invalid">
                      </dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="firstContactPhone"></dxi-item>
                  </dxi-item>
                  <dxi-item itemType="group" caption="Secondary Contact">
                    <dxi-item dataField="secondContactName"></dxi-item>
                    <dxi-item dataField="secondContactEmail">
                      <dxi-validation-rule
                              type="email"
                              message="Second contact email is invalid">
                      </dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="secondContactPhone"></dxi-item>
                  </dxi-item>
                </dxi-item>
                <dxi-item itemType="group" caption="Address">
                  <dxi-item dataField="address1" [isRequired]="true"></dxi-item>
                  <dxi-item dataField="address2"></dxi-item>
                  <dxi-item dataField="city" [isRequired]="true"></dxi-item>
                  <dxi-item dataField="state" [isRequired]="true"></dxi-item>
                  <dxi-item dataField="zip" [isRequired]="true"></dxi-item>
                  <dxi-item dataField="country" [isRequired]="true"></dxi-item>
                </dxi-item>
              </dxo-form>
            </dxo-editing>

            <dxo-selection mode="single"></dxo-selection>

            <dxi-column dataField="id" caption="ID" [width]="100"></dxi-column>
            <dxi-column sortOrder="asc" dataField="longName" caption="Long Name"></dxi-column>
            <dxi-column dataField="dispName" caption="Display Name"></dxi-column>
            <dxi-column caption="Address" cellTemplate="addrTemplate">
              <div *dxTemplate="let row of 'addrTemplate'">
                {{row.data.address1}}<br />
                <span *ngIf="row.data.address2 && row.data.address2.length > 0">{{row.data.address2}}<br /></span>
                {{row.data.city}}, {{row.data.state}} {{row.data.zip}}<br />
                {{row.data.country}}
              </div>
            </dxi-column>
            <dxi-column caption="Primary Contact" cellTemplate="ctc1Template">
              <div *dxTemplate="let row of 'ctc1Template'">
                {{row.data.firstContactName}}<br />
                {{row.data.firstContactEmail}}
                <span *ngIf="row.data.firstContactPhone && row.data.firstContactPhone.length > 0">
                  <br />{{row.data.firstContactPhone}}
                </span>
              </div>
            </dxi-column>
            <dxi-column dataField="enabled" caption="Enabled" [width]="100"  [alignment]="'center'" cellTemplate="enabledTemplate">
              <div *dxTemplate="let row of 'enabledTemplate'">
                {{(row.data.enabled && row.data.enabled === true ? "Yes" : "No")}}
              </div>
            </dxi-column>
            <!-- <dxi-column caption="Secondary Contact" cellTemplate="ctc2Template">
                <div *dxTemplate="let row of 'ctc2Template'">
                  {{row.data.secondContactName}}<br />
                  {{row.data.secondContactEmail}}
                  <span *ngIf="row.data.secondContactPhone && row.data.secondContactPhone.length > 0">
                    <br />{{row.data.secondContactPhone}}
                  </span>
                </div>
            </dxi-column> -->
            <dxi-column dataField="address1" caption="Line 1" [visible]="false"></dxi-column>
            <dxi-column dataField="address2" caption="Line 2" [visible]="false"></dxi-column>
            <dxi-column dataField="city" [visible]="false"></dxi-column>
            <dxi-column dataField="state" [visible]="false"></dxi-column>
            <dxi-column dataField="zip" caption="Zip/Code" [visible]="false"></dxi-column>
            <dxi-column dataField="country" [visible]="false"></dxi-column>
            <dxi-column dataField="firstContactName" caption="Name" [visible]="false"></dxi-column>
            <dxi-column dataField="firstContactEmail" caption="Email" [visible]="false"></dxi-column>
            <dxi-column dataField="firstContactPhone" caption="Phone" [visible]="false"></dxi-column>
            <dxi-column dataField="secondContactName" caption="Name" [visible]="false"></dxi-column>
            <dxi-column dataField="secondContactEmail" caption="Email" [visible]="false"></dxi-column>
            <dxi-column dataField="secondContactPhone" caption="Phone" [visible]="false"></dxi-column>
          </dx-data-grid>
        </div>
      <!-- </div>
  </app-panel> -->

  <div *ngIf="adminFilter === 'users' && (!allUsers || allUsers.length === 0)" class="none-available">Users data loading.</div>
  <!-- <app-panel *ngIf="adminFilter === 'users' && allUsers && allUsers.length > 0" [Title]="'All Users'" [MaximizeControls]="false" class="download-category-panel">
    <div id="users-div" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne"> -->
      <div *ngIf="adminFilter === 'users' && allUsers && allUsers.length > 0" class="admin-data-container">
        <dx-data-grid
                [dataSource]="allUsers"
                [masterDetail]="{ enabled: false }"
                [showBorders]="true"
                (onInitNewRow)="startUserCreate($event)"
                (onEditingStart)="startUserUpdate($event)"
                (onSaving)="sendUserUpdate($event)"
        >
          <dxo-editing
                  mode="form"
                  [allowUpdating]="true"
                  [allowDeleting]="false"
                  [allowAdding]="true"
          >
            <dxo-form [customizeItem]="changeUserFormProps">
              <dxi-item dataField="enabled" editorType="dxSwitch"></dxi-item><br />
              <dxi-item dataField="resetPasswordCompleted" editorType="dxSwitch"></dxi-item>
              <dxi-item dataField="id" [disabled]="true"></dxi-item>
              <dxi-item dataField="accountId" [isRequired]="true"></dxi-item>
              <dxi-item dataField="name" [isRequired]="true"></dxi-item>
              <dxi-item dataField="role" [isRequired]="true"></dxi-item>
              <dxi-item dataField="email" [isRequired]="true"></dxi-item>
              <dxi-item dataField="phone"></dxi-item>
              <dxi-item dataField="usernames" editorType="dxTagBox" [editorOptions]="{ acceptCustomValue: true }"></dxi-item>
              <dxi-item dataField="extraPrivileges" editorType="dxTagBox" [editorOptions]="{ acceptCustomValue: true }"></dxi-item>
              <dxi-item dataField="passwd1" [label]="{ text: 'Password' }" editorType="dxTextBox" [editorOptions]="{ mode: 'password' }"></dxi-item>
              <dxi-item dataField="passwd2" [label]="{ text: 'Confirm Password' }" editorType="dxTextBox" [editorOptions]="{ mode: 'password' }"></dxi-item>
            </dxo-form>
          </dxo-editing>

          <dxo-selection mode="single"></dxo-selection>

          <dxi-column sortOrder="asc" dataField="accountId" caption="Account">
            <dxo-lookup
              [dataSource]="allAccounts"
              valueExpr="id"
              displayExpr="dispName">
            </dxo-lookup>
          </dxi-column>
          <dxi-column sortOrder="asc" dataField="name" caption="Name"></dxi-column>
          <dxi-column dataField="email" caption="Email"></dxi-column>
          <dxi-column dataField="phone" caption="Phone"></dxi-column>
          <dxi-column dataField="usernames" caption="Usernames" cellTemplate="unameTemplate">
            <div *dxTemplate="let row of 'unameTemplate'">
                <span *ngIf="row.data.usernames && row.data.usernames[0]">
                  {{row.data.usernames.join(", ")}}
                </span>
            </div>
          </dxi-column>
          <dxi-column dataField="role" caption="Role" [width]="100"  [alignment]="'center'">
            <dxo-lookup
                    [dataSource]="roleNames"
                    valueExpr="id"
                    displayExpr="name">
            </dxo-lookup>
          </dxi-column>
          <dxi-column dataField="enabled" caption="Enabled" [width]="100"  [alignment]="'center'" cellTemplate="enabledTemplate">
            <div *dxTemplate="let row of 'enabledTemplate'">
              {{(row.data.enabled && row.data.enabled === true ? "Yes" : "No")}}
            </div>
          </dxi-column>
          <dxi-column dataField="id" caption="ID" [visible]="false"></dxi-column>
          <dxi-column dataField="resetPasswordCompleted" caption="Force Password Reset" [visible]="false"></dxi-column>
          <dxi-column dataField="extraPrivileges" caption="Extra Privileges" [visible]="false"></dxi-column>
          <dxi-column dataField="passwd1" caption="Password" [visible]="false"></dxi-column>
          <dxi-column dataField="passwd2" caption="Confirm Password" [visible]="false"></dxi-column>
        </dx-data-grid>
      </div>
    <!-- </div>
  </app-panel> -->

  <div *ngIf="adminFilter === 'licenses' && (!allLicenses || allLicenses.length === 0)" class="none-available">Licenses data loading.</div>
  <div *ngIf="adminFilter === 'licenses' && allLicenses && allLicenses.length > 0" class="admin-data-container">
    <dx-data-grid
            [dataSource]="allLicenses"
            [masterDetail]="{ enabled: false }"
            [showBorders]="true"
    >
      <dxo-editing
              mode="form"
              [allowUpdating]="true"
              [allowDeleting]="false"
              [allowAdding]="true"
      >
        <dxo-form>
          <dxi-item dataField="enabled" editorType="dxSwitch"></dxi-item><br />
          <dxi-item dataField="id" [disabled]="true"></dxi-item>
          <dxi-item dataField="accound_id" [disabled]="updatingLicense"></dxi-item>
          <dxi-item dataField="env_name" [disabled]="updatingLicense"></dxi-item>
          <dxi-item dataField="seats" [disabled]="updatingLicense"></dxi-item>
          <dxi-item dataField="expiration" [disabled]="updatingLicense"></dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-selection mode="single"></dxo-selection>

      <dxi-column sortOrder="asc" dataField="account_id" caption="Account">
        <dxo-lookup
                [dataSource]="allAccounts"
                valueExpr="id"
                displayExpr="dispName">
        </dxo-lookup>
      </dxi-column>
      <dxi-column sortOrder="asc" dataField="env_name" caption="Environment Name"></dxi-column>
      <dxi-column dataField="id" caption="ID"></dxi-column>
      <dxi-column dataField="expiration" caption="Expiration" [width]="150"></dxi-column>
      <dxi-column dataField="seats" caption="Seats" [width]="100"  [alignment]="'center'"></dxi-column>
      <dxi-column dataField="enabled" caption="Enabled" [width]="100"  [alignment]="'center'" cellTemplate="enabledTemplate">
        <div *dxTemplate="let row of 'enabledTemplate'">
          {{(row.data.enabled && row.data.enabled === true ? "Yes" : "No")}}
        </div>
      </dxi-column>
      <dxi-column dataField="file_exists" caption="File Exists" [width]="100"  [alignment]="'center'" cellTemplate="existsTemplate">
        <div *dxTemplate="let row of 'existsTemplate'">
          {{(row.data.file_exists && row.data.file_exists === true ? "Yes" : "No")}}
        </div>
      </dxi-column>
    </dx-data-grid>
  </div>

  <div *ngIf="adminFilter === 'announcements'" class="none-available">Not yet implemented.</div>

  <div *ngIf="adminFilter === 'documents'" class="none-available">Not yet implemented.</div>

  <div *ngIf="adminFilter === 'downloads'" class="none-available">Not yet implemented.</div>

</div>