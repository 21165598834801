import {Component, HostListener, OnInit, Pipe, PipeTransform} from '@angular/core';
import {DatePipe, TitleCasePipe} from "@angular/common";
import {ConfigService} from '../_services/helpers/config.service';
import {SupportConstants} from '../../support_constants';
import {LoggingService} from '../_services/functionality/logging.service';

import {AuthenticationService} from "../_services/functionality/authentication.service";
import {environment} from "../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {throwError as observableThrowError} from "rxjs/internal/observable/throwError";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {Router} from "@angular/router";

/** Main search page. handles before search, after search is done, and all search results. */
@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css'],
  providers: [TitleCasePipe, DatePipe]
})

export class DocumentationComponent implements OnInit {

  ConfigService = ConfigService;

  /** local instance of the platinum constants to be used in the template */
  constants: SupportConstants;

  /** local instance of the js object window to be used in the template */
  windowRef: Window;

  availableDocuments: any;

  baseUrl: string;
  docsUrls: any;
  activeDoc: string;
  docHtml: SafeHtml;

  /** @ignore */
  constructor(public authService: AuthenticationService, public router: Router, public http: HttpClient, public sanitizer: DomSanitizer) {

    this.constants = ConfigService.spConstants;
    this.windowRef = window;
    this.availableDocuments = [];
    this.docsUrls = {};
    this.activeDoc = "";
    this.docHtml = undefined;
    this.baseUrl = environment.webURLBase;
  }

  /** @ignore */
  async ngOnInit() {

    let result = await this.callDocumentsInfoService();
    if (result.documents) {
      this.availableDocuments = result.documents;
      for (let i = 0; i < this.availableDocuments.length; i++) {
        this.availableDocuments[i]["sortKey"] = this.availableDocuments[i]["parentId"] + "-" + this.availableDocuments[i]["order"].toString().padStart(3, "0") + "-" + this.availableDocuments[i]["title"];
        if (this.availableDocuments[i].type === 'document' || this.availableDocuments[i].type === 'external') {
          if (this.availableDocuments[i].url && this.availableDocuments[i].url.length > 0) {
            this.docsUrls[this.availableDocuments[i].id] = this.availableDocuments[i].url;
          } else if (this.availableDocuments[i].html) {
            this.docsUrls[this.availableDocuments[i].id] = environment.webURLBase + 'getdocument/?docId=' + encodeURIComponent(this.availableDocuments[i].id) + '&acctId=' + this.authService.accountData.uriEncodedId + '&token=' + encodeURIComponent(this.authService.authToken)
          }
        }
      }
      this.availableDocuments.sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1));
    } else {
      console.warn("No downloads are currently available.");
    }
  }

  private callDocumentsInfoService(): Promise<any> {

    if(!this.authService.accountData.id || this.authService.accountData.id === "")
      throw new Error("Account is not set. Cannot get download data.");

    // Get a account data after authorization:
    const url = environment.webURLBase + 'documents';

    return this.http.get<any>(url, this.authService.createRequestOptions())
        .pipe(
            map<HttpResponse<any>, any>(response => response.body),
            catchError((err) => {

              return observableThrowError(err);
            })
        ).toPromise();
  }

  public async updateHtml(docId: string) {
    if(this.activeDoc !== docId && this.docsUrls[docId] && this.docsUrls[docId].length > 0) {
      this.activeDoc = docId;
      // @ts-ignore
      let fetchedHtml = await this.http.get<any>(this.docsUrls[docId], {responseType: "text"}).toPromise();
      // @ts-ignore
      this.docHtml = this.sanitizer.bypassSecurityTrustHtml(fetchedHtml);
    }
  }

  private sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public async updateHtmlAndScroll(docId: string, anchor: string) {
    if(this.activeDoc !== docId && this.docsUrls[docId] && this.docsUrls[docId].length > 0) {
      this.activeDoc = docId;
      // @ts-ignore
      let fetchedHtml = await this.http.get<any>(this.docsUrls[docId], {responseType: "text"}).toPromise();
      // @ts-ignore
      this.docHtml = this.sanitizer.bypassSecurityTrustHtml(fetchedHtml);
      await this.sleep(250);  // a slight delay is needed for dom to update or the location.hash call below will fail
    }
    if(this.activeDoc === docId && anchor && anchor.length > 0)
      location.hash = "#" + anchor;
  }

  public encodeToUri(val: string): string {
    return encodeURIComponent(val);
  }
}
