import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ConfigService} from '../../_services/helpers/config.service';
import {AuthenticationService} from "../../_services/functionality/authentication.service";
import {DxValidationGroupComponent} from "devextreme-angular";
import {LoggingService} from "../../_services/functionality/logging.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {LOGOUT_REASON} from "../../_services/functionality/authentication.service";

/** subcomponent to handle the user resetting their password. */
@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  currentPassword: any = {
    value: ""
  };

  validatedNewPassword: string;


  /** local instance of the platinum constants to be used in the template */
  ConfigService = ConfigService;

  /** @ignore */
  constructor(public authService: AuthenticationService,
              public http: HttpClient) {
  }

  /** @ignore */
  ngOnInit() {
  }

  ngAfterViewInit() {

    //Ensure the loading spinner is not shown once our view is initialized:
  }

  async updateUserPassword(validationGroup: DxValidationGroupComponent) {

    // makes sure the passwords are validated before submitting
    const results = validationGroup.instance.validate();
    if ((results.isValid) && (this.validatedNewPassword)) {

      await this.authService.updateOwnPassword(this.validatedNewPassword)
    }
    else {
      LoggingService.showMessage('Your passwords are not validated. Make sure the two password fields match and are at least 10 characters long.', 'error', 'default');
    }

  }
}
