<div class="container-fluid docx-container-subnav docx-scrollbar">
  <app-subnav>
    <div left>
      <ul class="nav navbar-nav">
        <li [ngClass]="{'open':profileFilter === 'account'}">
          <a class="clickable pageable" (click)="profileFilter = 'account'" id="acctInfoBtn">
            <span *ngIf="windowRef.innerWidth >= 996">Account Info</span>
          </a>
        </li>

        <li [ngClass]="{'open':profileFilter === 'users'}">
          <a class="clickable pageable" (click)="profileFilter = 'users'" id="usersBtn">
            <span *ngIf="windowRef.innerWidth >= 996">Users</span>
          </a>
        </li>

      </ul>
    </div>
    <div right>

    </div>
  </app-subnav>
  If changes are needed for any values on this page, please open a support ticket.
  <br /><br />
  <div *ngIf="profileFilter === 'account'">
    <app-panel [Title]="'Organization'" [MaximizeControls]="false" class="info-panel">
      <div id="acct-info-div" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
        <div class="panel-body">
          <div>
            <table style="border: none; display: inline-block;">
              <tr>
                <td style="text-align: right;">Long/Legal Name:&nbsp;</td>
                <td>{{authService.accountData.longName}}</td>
              </tr>
              <tr>
                <td style="text-align: right;">Display Name:&nbsp;</td>
                <td>{{authService.accountData.dispName}}</td>
              </tr>
              <tr>
                <td style="text-align: right; vertical-align: top;">Address:&nbsp;</td>
                <td [innerHtml]="fullAddress" style="vertical-align: top;"></td>
              </tr>
              <tr>
                <td style="text-align: right;">Country:&nbsp;</td>
                <td>{{authService.accountData.country}}</td>
              </tr>
              <tr>
                <td colspan="2">&nbsp;</td>
              </tr>
              <tr>
                <td style="text-align: right;">Account ID:&nbsp;</td>
                <td>{{authService.accountData.id}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </app-panel>
    <app-panel [Title]="'Contacts'" [MaximizeControls]="false" class="info-panel">
      <div id="contacts-div" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
        <div class="panel-body">
          <table style="border: none; display: inline-block; margin-right: 30px;">
            <tr>
              <td colspan="3"><b>Primary Contact:</b></td>
            </tr>
            <tr>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              <td style="text-align: right;">Name:&nbsp;</td>
              <td>{{authService.accountData.firstContactName}}</td>
            </tr>
            <tr>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              <td style="text-align: right;">Email:&nbsp;</td>
              <td>{{authService.accountData.firstContactEmail}}</td>
            </tr>
            <tr *ngIf="authService.accountData.firstContactPhone && authService.accountData.firstContactPhone.length > 0">
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              <td style="text-align: right;">Phone:&nbsp;</td>
              <td>{{authService.accountData.firstContactPhone}}</td>
            </tr>
          </table>
          <table *ngIf="authService.accountData.secondContactName && authService.accountData.secondContactName.length > 0 && authService.accountData.secondContactEmail && authService.accountData.secondContactEmail.length > 0" style="border: none; display:inline-block;" cellspacing="0" cellpadding="0">
            <tr>
              <td colspan="3"><b>Secondary Contact:</b></td>
            </tr>
            <tr>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              <td style="text-align: right;">Name:&nbsp;</td>
              <td>{{authService.accountData.secondContactName}}</td>
            </tr>
            <tr>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              <td style="text-align: right;">Email:&nbsp;</td>
              <td>{{authService.accountData.secondContactEmail}}</td>
            </tr>
            <tr *ngIf="authService.accountData.secondContactPhone && authService.accountData.secondContactPhone.length > 0">
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              <td style="text-align: right;">Phone:&nbsp;</td>
              <td>{{authService.accountData.secondContactPhone}}</td>
            </tr>
          </table>
          <table *ngIf="!authService.accountData.secondContactName || authService.accountData.secondContactName.length === 0 || !authService.accountData.secondContactEmail || authService.accountData.secondContactEmail.length === 0" style="border: none; display:inline-block;" cellspacing="0" cellpadding="0">
            <tr>
              <td colspan="3"><b>Secondary Contact:</b></td>
            </tr>
            <tr>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              <td colspan="2">Secondary contact not on file.</td>
            </tr>
          </table>
        </div>
      </div>
    </app-panel>
  </div>
  <div *ngIf="profileFilter === 'users'">
    <app-panel [Title]="'Support Site Users'" [MaximizeControls]="false" class="info-panel">
      <div id="acct-users-div" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
        <div class="panel-body">
          <dx-data-grid id="users-table" *ngIf="authService.accountData.users"
                  [dataSource]="authService.accountData.users"
                  [masterDetail]="{ enabled: false }"
          >
            <dxo-editing
                    mode="form"
                    [allowUpdating]="false"
                    [allowDeleting]="false"
                    [allowAdding]="false"
            >
            </dxo-editing>

            <dxo-selection mode="single"></dxo-selection>

            <dxi-column sortOrder="asc" dataField="name" caption="Name"></dxi-column>
            <dxi-column dataField="email" caption="Email"></dxi-column>
            <dxi-column dataField="phone" caption="Phone"></dxi-column>
            <dxi-column dataField="usernames" caption="Usernames" cellTemplate="unameTemplate">
              <div *dxTemplate="let row of 'unameTemplate'">
                <span *ngIf="row.data.usernames && row.data.usernames[0]">
                  {{row.data.usernames.join(", ")}}
                </span>
              </div>
            </dxi-column>
            <dxi-column dataField="enabled" caption="Enabled" [width]="100" [alignment]="'center'" cellTemplate="enableTemplate">
              <div *dxTemplate="let row of 'enableTemplate'">
                {{(row.data.enabled === true || row.data.enabled === 'true' ? 'Yes' : 'No')}}
              </div>
            </dxi-column>
          </dx-data-grid>
          <span *ngIf="!authService.accountData.users">No users were found for this account!</span>
          </div>
        </div>
    </app-panel>
  </div>
</div>