<div class="container-fluid docx-container-no-subnav docx-scrollbar">
  <div class="landing-info">
  This site provides access to Docxonomy documentation and downloads. It can be used to update account details like the technical contact or address information, and also provides a link to open support tickets under the menu in the top right corner.</div>
  <br />
  <div *ngIf="messages">
    <app-panel *ngFor="let msg of messages" [Title]="msg.title" [MaximizeControls]="false" class="msg-panel">
      <span *ngIf="msg.subhead" class="msg-subhead">{{msg.subhead}}<br /></span>
      <div id="msg-div" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
        <div class="panel-body">
          <span *ngIf="msg.topic">{{msg.topic}}<br /></span>
          <span *ngIf="msg.author">Posted by:&nbsp;{{msg.author}}<br /></span>
          <span *ngIf="msg.date">Date:&nbsp;{{msg.date}}<br /></span>
          <br *ngIf="msg.topic || msg.author || msg.date" />
          <span [innerHtml]="msg.html | safeHtml"></span>
        </div>
      </div>
    </app-panel>
  </div>
  <div *ngIf="!messages" class="msg-panel">
    Docxonomy may post information of interest to this page like release notes, upcoming events, etc.<br />
    <br />
    Currently no messages are available.
  </div>
</div>