import {HostListener, PLATFORM_ID, Inject, Injectable} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';

/** extension of device functionality
 */
@Injectable()
export class DeviceService extends DeviceDetectorService {

  public static MIN_BROWSER_HEIGHT: number = 600;
  public static MIN_MOBILE_BROWSER_HEIGHT: number = 500;

  public browserWindow: Window = window; //Expose the window object so we can reference it from HTML templates too
  public orientation: string;
  public _isMobile: boolean; //Same as calling "isMobile()", but making available as a regular property to reference in HTML templates without repeated calls by angular on each digest cycle!
  public landscapeAcceptable: boolean = false;
  public portraitAcceptable: boolean = false;

  /** @ignore
   */
  constructor(@Inject(PLATFORM_ID) private platformIdentifier: any) {

    super(platformIdentifier);

    this._isMobile = this.isMobile() || this.device === 'ipad';

    //Set the initial orientation:
    this.setOrientation();

    if (this._isMobile){
      if (this.orientation === 'landscape'){
        if(this.browserWindow.innerHeight >= DeviceService.MIN_MOBILE_BROWSER_HEIGHT){
          this.landscapeAcceptable = true;
        }
        if(this.browserWindow.innerWidth >= DeviceService.MIN_MOBILE_BROWSER_HEIGHT){
          this.portraitAcceptable = true;
        }
      }else if (this.orientation === 'portrait'){
        if(this.browserWindow.innerWidth >= DeviceService.MIN_MOBILE_BROWSER_HEIGHT){
          this.landscapeAcceptable = true;
        }
        if(this.browserWindow.innerHeight >= DeviceService.MIN_MOBILE_BROWSER_HEIGHT){
          this.portraitAcceptable = true;
        }
      }
    }


  }

  public setOrientation() {
    this.orientation = (this.browserWindow.orientation === 90 || this.browserWindow.orientation === -90) ? "landscape" : "portrait";
  }
}

