import { Component, OnInit, Input} from '@angular/core';
import {DeviceService} from "../../_services/functionality/device.service";
import {AuthenticationService} from "../../_services/functionality/authentication.service";

/** subcomponent to handle displaying the loading indicator */
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  /** whether or not the loading indicator should be shown */
  @Input('Loading') loading: boolean;

  /** whether or not there should be a backdrop behind the loader */
  @Input('Backdrop') backdrop: boolean;

  /** local instance of the window js object to be used in the template */
  windowRef: Window;

  /** @ignore */
  constructor(public _deviceService: DeviceService, public authService: AuthenticationService) {

    this.windowRef = window;
  }

  /** @ignore */
  ngOnInit() {}


}
