<nav class="navbar navbar-default navbar-fixed-top-sub docx-sub-nav theme-{{authService.accountData.theme}}-subnav col-md-{{width ? (width) : 12}} col-sm-12 col-xs-12 col-lg-{{width ? (width) : 12}} col-md-offset-{{offset ? offset : 0}} col-lg-offset-{{offset ? offset : 0}} col-sm-offset-0 col-xs-offset-0" >
<div class="container-fluid">
    <div class="navbar-header">
      <div class="navbar-left {{windowRef.innerWidth >= 767 ? 'larger-size' : 'smaller-size'}}">
        <ng-content select="[left]"></ng-content>
      </div>
    </div>
    <div class="navbar-right {{windowRef.innerWidth >= 767 ? 'larger-size' : 'smaller-size'}}">
      <ng-content select="[right]"></ng-content>
    </div>
  </div>
</nav>
