import {Component, HostListener, OnInit} from '@angular/core';
import {DatePipe, TitleCasePipe} from "@angular/common";
import {ConfigService} from '../_services/helpers/config.service';
import {SupportConstants} from '../../support_constants';
import { confirm } from 'devextreme/ui/dialog';
import {LoggingService} from '../_services/functionality/logging.service';

import {AuthenticationService, UserData, AccountData} from "../_services/functionality/authentication.service";
import {environment} from "../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {throwError as observableThrowError} from "rxjs/internal/observable/throwError";
import DevExpress from "devextreme";
import dxToast = DevExpress.ui.dxToast;

export class LookupField {
  id: string;
  name: string;
}

let disableAcctId: boolean;
let requireUserPass: boolean;

/** Main search page. handles before search, after search is done, and all search results. */
@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.css'],
  providers: [TitleCasePipe, DatePipe]
})


export class AdministrationComponent implements OnInit {

  ConfigService = ConfigService;

  /** local instance of the platinum constants to be used in the template */
  constants: SupportConstants;

  /** local instance of the js object window to be used in the template */
  windowRef: Window;

  adminFilter: string;

  allAccounts: AccountData[];
  allLicenses: any[];
  allUsers: UserData[];

  updatingLicense: boolean;

  roleNames: LookupField[];

  /** @ignore */
  constructor(public authService: AuthenticationService, public http: HttpClient) {

    this.constants = ConfigService.spConstants;
    this.windowRef = window;

    this.adminFilter = "accounts";

    this.allAccounts = undefined;
    this.allLicenses = undefined;
    this.allUsers = undefined;

    disableAcctId = true;
    requireUserPass = true;
    this.updatingLicense = true;

    this.roleNames = [];
    this.roleNames.push({id: "user", name: "User"});
    this.roleNames.push({id: "admin", name: "Admin"});
  }

  /** @ignore */
  async ngOnInit() {

    disableAcctId = true;
    this.updatingLicense = true;

    let _this = this;

    let acctBody = await this.callInfoService("getaccounts");

    if(acctBody.accounts && acctBody.accounts.length > 0)
      _this.allAccounts = [];
      for(let i = 0; i < acctBody.accounts.length; i++) {
        let acct: AccountData = {
          id: acctBody.accounts[i].id,
          enabled: acctBody.accounts[i].enabled,
          longName: acctBody.accounts[i].long_name,
          dispName: acctBody.accounts[i].disp_name,
          address1: acctBody.accounts[i].address1,
          city: acctBody.accounts[i].city,
          state: acctBody.accounts[i].state,
          zip: acctBody.accounts[i].zip,
          country: acctBody.accounts[i].country,
          firstContactName: acctBody.accounts[i].primary_first + " " + acctBody.accounts[i].primary_last,
          firstContactEmail: acctBody.accounts[i].primary_email
        };
        if(acctBody.accounts[i].address2 && acctBody.accounts[i].address2.length > 0)
          acct.address2 = acctBody.accounts[i].address2;
        if(acctBody.accounts[i].primary_phone && acctBody.accounts[i].primary_phone.lenth > 0)
          acct.firstContactPhone = acctBody.accounts[i].primary_phone;
        if(acctBody.accounts[i].secondary_first && acctBody.accounts[i].secondary_first.length > 0 && acctBody.accounts[i].secondary_last && acctBody.accounts[i].secondary_last.length > 0)
          acct.secondContactName = acctBody.accounts[i].secondary_first + " " + acctBody.accounts[i].secondary_last;
        if(acctBody.accounts[i].secondary_email && acctBody.accounts[i].secondary_email.length > 0)
          acct.secondContactEmail = acctBody.accounts[i].secondary_email;
        if(acctBody.accounts[i].secondary_phone && acctBody.accounts[i].secondary_phone.length > 0)
          acct.secondContactPhone = acctBody.accounts[i].secondContactPhone;

        _this.allAccounts.push(acct);
      }

      // use .then here instead of await because the getusers and getlicenses calls can happen at the same time
      this.callInfoService("getusers")
          .then(function(body) {
            if(body.users && body.users.length > 0)
              _this.allUsers = [];
            for(let i = 0; i < body.users.length; i++) {
              let usr: UserData = {
                id: body.users[i].id,
                enabled: body.users[i].enabled,
                name: body.users[i].name
              };
              if(body.users[i].email && body.users[i].email.length > 0)
                usr.email = body.users[i].email;
              if(body.users[i].account_id && body.users[i].account_id.length > 0)
                usr.accountId = body.users[i].account_id;
              if(body.users[i].reset_password_completed !== null && body.users[i].reset_password_completed !== undefined)
                usr.resetPasswordCompleted = !body.users[i].reset_password_completed;
              if(body.users[i].phone && body.users[i].phone.length > 0)
                usr.phone = body.users[i].phone;
              if(body.users[i].usernames && body.users[i].usernames.length > 0)
                usr.usernames = body.users[i].usernames;
              if(body.users[i].role && body.users[i].role.length > 0)
                usr.role = body.users[i].role;
              else
                usr.role = "user";
              if(body.users[i].extra_privileges && body.users[i].extra_privileges.length > 0)
                usr.extraPrivileges = body.users[i].extra_privileges;

              _this.allUsers.push(usr);
            }
          });

    // use .then here instead of await because the getusers and getlicenses calls can happen at the same time
      this.callInfoService("getlicenses")
          .then(function(body) {
            if(body.licenses && body.licenses.length > 0)
              _this.allLicenses = body.licenses;
          });

  }

  private callInfoService(svcName: string): Promise<any> {

    if(!this.authService.accountData.id || this.authService.accountData.id === "")
      throw new Error("Account is not set. Cannot get data from " + svcName + " service.");

    const url = environment.admURLBase + svcName;

    return this.http.get<any>(url, this.authService.createRequestOptions())
        .pipe(
            map<HttpResponse<any>, any>(response => response.body),
            catchError((err) => {

              return observableThrowError(err);
            })
        ).toPromise();
  }

  private callUpdateService(svcName: string, data: any): Promise<any> {

    if(!this.authService.accountData.id || this.authService.accountData.id === "")
      throw new Error("Account is not set. Cannot update data for " + svcName + " service.");

    const url = environment.admURLBase + svcName;

    return this.http.post<any>(url, data, this.authService.createRequestOptions())
        .pipe(
            map<HttpResponse<any>, any>(response => response.body),
            catchError((err) => {

              return observableThrowError(err);
            })
        ).toPromise();
  }

  public startAcctCreate(e) {
    disableAcctId = false;
  }

  public startUserCreate(e) {
    requireUserPass = true;
  }

  public startAcctUpdate(e) {
    disableAcctId = true;
  }

  public startUserUpdate(e) {
    requireUserPass = false;
  }

  public sendAcctUpdate(e) {

    if(e.changes && e.changes[0] && e.changes[0].key && e.changes[0].data) {
      let type = e.changes[0].type;
      let acctData = { ...e.changes[0].key, ...e.changes[0].data };

      if(acctData.secondContactName && acctData.secondContactName.length > 0 && (!acctData.secondContactEmail || acctData.secondContactEmail.length === 0)) {
        e.cancel = true;
        LoggingService.showMessage("An email must be provided for the secondary contact if a name is given.", "error", "default");
      }
      else if((!acctData.secondContactName || acctData.secondContactName.length === 0) && acctData.secondContactEmail && acctData.secondContactEmail.length > 0) {
        e.cancel = true;
        LoggingService.showMessage("An name must be provided for the secondary contact if an email is given.", "error", "default");
      }
      else if((!acctData.secondContactName || acctData.secondContactName.length === 0) && acctData.secondContactPhone && acctData.secondContactPhone.length > 0) {
        e.cancel = true;
        LoggingService.showMessage("An name must be provided for the secondary contact if a phone number is given.", "error", "default");
      }
      else {
        let resp;
        if(type === "insert")
          resp = this.callUpdateService("createaccount", acctData);
        else if(type === "update")
          resp = this.callUpdateService("updateaccount", acctData);

          e.promise = resp
              .then(function(body) {
                if(body && body.server_error) {
                  e.cancel = true;  // I do now know whether or not the event variable e is still in scope when the .then executes...
                  console.error("Error saving account data - full response: ", body);
                  if(resp.server_error.message)
                    LoggingService.showMessage(resp.server_error.message, "error", "default");
                  else
                    LoggingService.showMessage("There was a problem saving the account data.", "error", "default");
                }
                else if(body && body.saved)
                  LoggingService.showMessage("Account data saved.", "info", "default");
              }
          )
              .catch(function(err) {
                e.cancel = true;  // I do now know whether or not the event variable e is still in scope when the .catch executes...
                console.error("Error saving account data - full error response: ", err);
                if(err && err.error && err.error.server_error) {
                  if(err.error.server_error.message)
                    LoggingService.showMessage(err.error.server_error.message, "error", "default");
                  else
                    LoggingService.showMessage("There was a problem saving the account data.", "error", "default");
                }
                else {
                  LoggingService.showMessage("There was an unexpected problem saving the account data.", "error", "default");
                }
              });
      }
    }
    else {
      e.cancel = true;
    }
  }

  public sendUserUpdate(e) {

    console.log("sendUserUpdate event: ", e);
    if(e.changes && e.changes[0] && e.changes[0].key && e.changes[0].data) {
      let type = e.changes[0].type;
      let userData = { ...e.changes[0].key, ...e.changes[0].data };

      if(!userData.accountId.startsWith("internal-") && userData.role === "admin") {
        e.cancel = true;
        LoggingService.showMessage("Cannot assign the \"Admin\" role to a user in a non-internal account.", "error", "default");
      }
      else if(userData.role === 'user' && userData.extraPrivileges && userData.extraPrivileges.length > 0)
      {
        e.cancel = true;
        LoggingService.showMessage("Cannot assign extra privileges to non-admin users.", "error", "default");
      }
      else if(userData.passwd1 !== userData.passwd2) {
        e.cancel = true;
        LoggingService.showMessage("Password fields do not match.", "error", "default");
      }
      else {

        if(userData.passwd1 && userData.passwd1.length > 0)
          userData.passwd = userData.passwd1;

        if(userData.resetPasswordCompleted !== null && userData.resetPasswordCompleted !== undefined)
          userData.resetPasswordCompleted = !userData.resetPasswordCompleted; // value is switched between backend and UI

        delete userData.passwd1;
        delete userData.passwd2;

        let resp;
        if(type === "insert")
          resp = this.callUpdateService("createuser", userData);
        else if(type === "update")
          resp = this.callUpdateService("updateuser", userData);

        e.promise = resp
            .then(function(body) {
                  if(body && body.server_error) {
                    e.cancel = true;  // I do now know whether or not the event variable e is still in scope when the .then executes...
                    console.error("Error saving user data - full response: ", body);
                    if(resp.server_error.message)
                      LoggingService.showMessage(resp.server_error.message, "error", "default");
                    else
                      LoggingService.showMessage("There was a problem saving the user data.", "error", "default");
                  }
                  else if(body && body.saved)
                    LoggingService.showMessage("User data saved.", "info", "default");
                }
            )
            .catch(function(err) {
              e.cancel = true;  // I do now know whether or not the event variable e is still in scope when the .catch executes...
              console.error("Error saving user data - full error response: ", err);
              if(err && err.error && err.error.server_error) {
                if(err.error.server_error.message)
                  LoggingService.showMessage(err.error.server_error.message, "error", "default");
                else
                  LoggingService.showMessage("There was a problem saving the user data.", "error", "default");
              }
              else {
                LoggingService.showMessage("There was an unexpected problem saving the user data.", "error", "default");
              }
            });
      }
    }
    else {
      e.cancel = true;
    }
  }

  public endAcctCreateOrUpdate(e) {
    disableAcctId = true;
  }

  public changeAcctFormProps(item) {
    if(item && item.dataField === "id") {
      item.disabled = disableAcctId;
    }
  }

  public changeUserFormProps(item) {
    if(item && item.dataField === "passwd1") {
      console.log("requiring passwd1: ", requireUserPass);
      item.isRequired = requireUserPass;
    }
  }
}
