<div class="collapse navbar-collapse {{windowRef.innerWidth >= 768 ? 'not-collapsed' : 'docx-scrollbar'}}" id="docx-navbar-collapsed">
  <ul class="nav navbar-nav docx-navbar">
    <li routerLinkActive="open">
      <a class="clickable pageable normal-main-nav-a" routerLink="/documentation" routerLinkActive="page-active" id="documentation-page-btn">
        <app-icon [IconName]="ConfigService.spConstants.icons.documentation.icon" [IconType]="ConfigService.spConstants.icons.documentation.type"></app-icon>
        <span *ngIf="windowRef.innerWidth >= navbarBreakWidth || windowRef.innerWidth <= 767">Documentation</span>
      </a>
    </li>

    <li routerLinkActive="open">
      <a class="clickable pageable normal-main-nav-a" routerLink="/downloads" routerLinkActive="page-active"  id="downloads-page-btn">
        <app-icon [IconName]="ConfigService.spConstants.icons.downloads.icon" [IconType]="ConfigService.spConstants.icons.downloads.type"></app-icon>
        <span *ngIf="windowRef.innerWidth >= navbarBreakWidth || windowRef.innerWidth <= 767">Downloads</span>
      </a>
    </li>

    <li routerLinkActive="open" *ngIf="authService.userData && authService.userData.role && authService.userData.role === 'admin'">
      <a class="clickable pageable normal-main-nav-a" routerLink="/admin" routerLinkActive="page-active" id="admin-page-btn">
        <app-icon [IconName]="ConfigService.spConstants.icons.admin.icon" [IconType]="ConfigService.spConstants.icons.admin.type"></app-icon>
        <span *ngIf="windowRef.innerWidth >= navbarBreakWidth || windowRef.innerWidth <= 767">Administration</span>
      </a></li>
  </ul>


  <ul class="nav navbar-nav navbar-right docx-navbar {{windowRef.innerWidth >= 767 ? 'larger-size' : 'smaller-size'}}">

    <li class="dropdown header-dropdown" id="dropdown-check" routerLinkActive="settings-section-page-open">
      <a href="#" class="dropdown-img-and-accountname dropdown-toggle pageable" data-toggle="dropdown" routerLinkActive="page-active">

        <img src="../../../assets/images/defaultUser.jpg" *ngIf="!authService.accountData.logo || authService.accountData.logo === 'default'" class="profile-image img-circle" height="28">
        <img [src]="authService.accountData.logo" *ngIf="authService.accountData.logo && authService.accountData.logo !== 'default'" class="profile-image img-circle" height="28">
        {{authService.accountData.dispName | truncate : truncateCompanyName : '...'}}
        <b class="caret"></b>
      </a>
      <ul class="dropdown-menu">
        <li class="dropdown-menu-container settings-section-flag">
          <div class="navbar-content header-profile-section">
            <div class="row">
              <div class="col-xs-4 col-sm-4 col-md-4 profile-img-container {{windowRef.innerWidth <= 768 ? 'collapsed-nav-dropdown' : ''}}">

                <a (click)="changePhoto()" class="clickable">
                  <img src="/assets/images/defaultUser.jpg" *ngIf="!authService.accountData.logo || authService.accountData.logo === 'default'" class="profile-image img-circle" class="img-responsive profile-img settings-dropdown-img">
                  <img [src]="authService.accountData.logo" *ngIf="authService.accountData.logo && authService.accountData.logo !== 'default'" class="profile-image img-circle" class="img-responsive profile-img settings-dropdown-img"></a>
              </div>
              <div class="col-xs-8 col-sm-8 col-md-8 {{windowRef.innerWidth <= 768 ? 'collapsed-nav-dropdown' : ''}}">
                {{authService.accountData.longName | truncate : 30 :'...'}}
                <hr class="decorated-hr decorated-hr-nav">
                <span class="users-name">{{authService.userData.name}}</span>
                <p class="email">
                  {{authService.userData.email}}</p>
              </div>
            </div>
          </div>
          <div class="navbar-footer inverse">
            <div class="navbar-footer-content">
              <div class="row">
                <div class="btn-container col-xs-4 col-sm-4 col-md-4">
                  <a href="https://docxonomy.zendesk.com/hc/en-us/requests/new" target="_blank" class="btn btn-default btn-sm btn-settings">
                    Open Ticket</a>
                </div>
                <div class="btn-container col-xs-4 col-sm-4 col-md-4">
                  <a routerLink="/profile" class="btn btn-default btn-sm btn-settings">
                    Profile</a>
                </div>
                <div class="btn-container col-xs-4 col-sm-4 col-md-4">
                  <a routerLink="/login" (click)="authService.clearAllData();" class="btn btn-default btn-sm btn-settings">
                    <app-icon [IconName]="ConfigService.spConstants.icons.signout.icon" [IconType]="ConfigService.spConstants.icons.signout.type"></app-icon>
                    Sign Out</a>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </li>

  </ul>
</div>
