import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from "../../_services/functionality/authentication.service";

/** subcomponent to display a subnav bar with transclusion for any content to add to it. */
@Component({
  selector: 'app-subnav',
  templateUrl: './subnav.component.html',
  styleUrls: ['./subnav.component.css']
})
export class SubnavComponent implements OnInit {
  /** width of the subnav... in bootstrap column size */
  @Input("Width") width: any;

  /** left offset of the subnav... in bootstrap column size */
  @Input("Offset") offset: any;

  /** the bg color of the subnav */
  @Input("BGColor") bg_color: string;

  /** the font color of the subnav */
  @Input("FontColor") font_color: string;

  /** local instance of the js window object to be used in the template */
  windowRef: Window;

  /** @ignore */
  constructor(public authService: AuthenticationService) {

    this.windowRef = window;
  }

  /** @ignore */
  ngOnInit() {
  }

}
