import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationEnd} from '@angular/router';
import { Observable, of } from 'rxjs';
import {AuthenticationService, LOGOUT_REASON} from "../_services/functionality/authentication.service";
import {GeneralUtilService, ParsedURL} from "../_services/helpers/general-util.service";
import {LoggingService} from "../_services/functionality/logging.service";
import {ConfigService} from "../_services/helpers/config.service";

@Injectable()
export class AuthGuard implements CanActivate {



  constructor(public authService: AuthenticationService, public router: Router) {

  }

  //Called by the angular router to check if the requested route can be allowed.  A true allows the route, a false denies it.
  //If the boolean is wrapped in an Observable or Promise, the router will wait for the result to come through the Observable
  //before proceeding to allow or deny.
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    console.log("url: ", this.router.url);
    if (this.authService.isLoggedIn) {

      return true;
    }
    else {

      //Clear the in-memory values that are tied to the user's login (if any are set).  This also clears the values that
      //the basedDataFullyAvailable observable is based on.
      this.authService.resetValuesForNewLogin();

      if (await this.checkLocalStorageForNonExpiredUserAndReactivateSession()) {

        // Go to reset password page if the resetPasswordCompleted flag is false and we are not already on the /password-reset route
        if(this.authService.userData && this.authService.userData.resetPasswordCompleted !== null && this.authService.userData.resetPasswordCompleted !== undefined && this.authService.userData.resetPasswordCompleted === false && !this.router.url.endsWith("/password-reset")) {
          await this.router.navigate(["/password-reset"]);
        }

        return true;
      }
      else { //No choice left but to route the user to the login page:

        this.router.navigate(['/login']);

        return false;
      }
    }
  }

  /** handles checking localstorage for any of our docxonomy information - which is needed if the user refreshed the entire page but did not logout.
   * */
  async checkLocalStorageForNonExpiredUserAndReactivateSession() {

    try {

      // if we have an email that is stored in local storage and a valid token, then the user is still logged in:
      const accountID: string = localStorage.getItem(GeneralUtilService.LocalStorage.account_id);
      const accountDisplayName: string = localStorage.getItem(GeneralUtilService.LocalStorage.account_name);
      const authToken: string = localStorage.getItem(GeneralUtilService.LocalStorage.token);
      const localLastActive: number = Number.parseInt(localStorage.getItem(GeneralUtilService.LocalStorage.lastActive));
      const currTime = new Date().getTime();

      if ((((currTime - localLastActive) / ConfigService.spConstants.general.millisecondsToMinutes) < ConfigService.spConstants.general.allowedExpirationInMinutes)
        && (authToken) && (authToken !== 'undefined') && (authToken.length > 0)) {

        //Since their are valid values in local storage, use them to setup a user session:
        await this.authService.setupAuthorizedUserSession({ id: accountID, enabled: true, dispName: accountDisplayName, theme: 'dark_gray' }, authToken);

        return true;
      }
      else { //User does not have a valid session

        //Clear any values that might be in memory/local storage and return false to the calling routine:
        this.authService.clearAllData();

        return false;
      }
    }
    catch (err) {

      // todo convert into docxonomy logging
      console.error(err);
      LoggingService.showMessage(err, 'error', 'default');

      //Clear any values that might be in memory/local storage and return false to the calling routine:
      this.authService.clearAllData();

      return false;
    }
  }
}
