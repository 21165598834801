import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfigService} from '../../../_services/helpers/config.service';
import {GeneralUtilService} from '../../../_services/helpers/general-util.service';
import {LoggingService} from '../../../_services/functionality/logging.service';
import {AuthenticationService} from "../../../_services/functionality/authentication.service";

/** subcomponent that gets shown when the user is logged in and properly authenticated or "authorized".  */
@Component({
  selector: 'app-authorized-header',
  templateUrl: './authorized-header.component.html',
  styleUrls: ['./authorized-header.component.css']
})
export class AuthorizedHeaderComponent implements OnInit {

  /** the number to truncate the company name based on the size of the window */
  @Input('TruncateCompanyName') truncateCompanyName: number;

  /** a local copy of the account id so that we can register if there has been a change to the account */
  localAccountId: string;

  /** number of width when the navbar should break */
  navbarBreakWidth: number;

  /** local instance of js window object to be used in the template */
  windowRef: Window;

  ConfigService = ConfigService;

  /** @ignore */
  constructor(public authService: AuthenticationService) {

    this.windowRef = window;
  }

  /** @ignore */
  ngOnInit() {
    this.navbarBreakWidth = 1140;
    this.localAccountId = this.authService.accountData.id;

  }

  /** wrapper around the copy function in the general service to make sure that we're copying the user's email */
  copyUserEmail(): void {

    GeneralUtilService.copy(this.authService.userData.email);
    LoggingService.showMessage('The email address has been copied to your clipboard.', 'success', 'default')
  }

  /** wrapper around the copy function in the general service to make sure that we're copying the user's email */
  copyAccountEmail(): void {

    GeneralUtilService.copy(this.authService.accountData.firstContactEmail);
    LoggingService.showMessage('The email address has been copied to your clipboard.', 'success', 'default')
  }

  /** when the user clicks change photo in the settings dropdown, then send the user to the profile page so they can change their photo */
  changePhoto(): void {
    // figure out implementation for logo change
  }
}
