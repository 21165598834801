import {Component, OnInit, Input, Output, EventEmitter , HostListener} from '@angular/core';
import {ConfigService} from '../../_services/helpers/config.service';
import {IconStructure, SupportConstants} from '../../../support_constants';
import {AuthenticationService} from "../../_services/functionality/authentication.service";

/** configuration to set up a unique button on the top right of the modal */
export interface ModalButtonConfig {
  /** what you want the title to be */
  title: string;

  /** what the text should be */
  text: string;

  /** an icon to use, if wanted */
  icon?: IconStructure;
}

/** Structure of an icon. */
export interface Icon {
  /** which icon library to use. */
  type: string;

  /** the name of the icon within whatever library it is. */
  icon: string;
}

/** subcomponent that extends bootstrap 3's modal functionality. extra css makes modal take up entire screen on mobile as well for better experience */
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  /** title of the modal. will be displayed on the top left of the modal */
  @Input('Title') title: string;

  /** icon of the modal. will be displayed on the top left of the modal */
  @Input('Icon') icon: Icon;

  /** a unique modal id.... IMPORTANT: this must be unique to other modal ids otherwise there may be errors that occur */
  @Input('ModalId') modalId: string;

  /** whether or not this modal is full screen  */
  @Input('FullScreen') fullScreen: boolean;

  /** whether or not there should be no padding to the modal */
  @Input('NoPadding') noPadding: boolean;

  /** a help desk recommendation that can be used for this modal */
  @Input('HelpDeskRecommendation') searchRecommendation: string;

  /** the page associating with routing to this particular modal. */
  @Input('pageForRouting') routingPage: string;

  /** whether or not the user has the ability to close the modal with the normal close button */
  @Input('disableClose') disableClose: boolean;

  /** a configuration to create another button seperate from the close button. this will be displayed on the top right of the modal */
  @Input('BtnConfig') btnConfig: ModalButtonConfig;



  /** handles emitting an event when the custom button is clicked */
  @Output('sendValueOut') sendValueOut = new EventEmitter<any>();

  /** handles emitting an event when the modal is closed */
  @Output('modalClosed') modalClosed = new EventEmitter<any>();

  /** handles emitting an event when enter is typed in while modal is opening */
  @Output('enterClicked') enterClicked = new EventEmitter<any>();



  /** a value used to truncate the modal title */
  modalTitleTruncate: number;

  /** local instance of the shared service to be used in the template */
  service:any;

  /** local instance of the js object window to be used in the template */
  windowRef: Window;

  /** local instance of the platinum constants to be used in the template */
  constants: SupportConstants;

  /** theme to use */
  theme: string;

  /** host listener on the keys to track whether or not enter was clicked while on this modal */
  //TODO: Future Enhancement This could possibly be simplified by binding to Angular's keyup.enter pseudo-event (see https://angular.io/guide/user-input)
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    if(this.routingPage) {
      if ((event.keyCode === 13 || event.code === "Enter")) {
        this.enterClicked.emit('');
      }
    }
  }

  /** reset the modalTitleTruncate value anytime the window resizes */
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setModalTruncateTitle();
  }

  /** @ignore */
  constructor(public authService: AuthenticationService) {
    this.windowRef = window;
    this.constants = ConfigService.spConstants;

  }

  /** @ignore */
  ngOnInit() {
    this.setModalTruncateTitle();

    //Defaults to a theme when one cannot be determined from the applicable account (if available):
    this.theme = (this.authService.accountData) ? this.authService.accountData.theme : "dark_gray";
  }

  /** @ignore */
  ngOnChanges() {
    this.setModalTruncateTitle();
  }

  /** handles opening the help center widget at a specific search recommendation page */
  openHelpWidget(): void {
    // TODO: do something here
  }

  /** emits an event when the custom button being clicked */
  sendOutValue(): void {



    if(this.title === 'Upload') {
      this.sendValueOut.emit('upload');
    }else {
      this.sendValueOut.emit('clear');
      this.modalClosedClicked();
    }
  }

  /** emits an event when the modal is closed */
  modalClosedClicked(): void {
    this.modalClosed.emit('closed');
  }

  /** sets the truncate value for the title */
  setModalTruncateTitle(): void {
    this.modalTitleTruncate = Math.floor((window.innerWidth * 30)/1280);
  }

}
