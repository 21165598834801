<div class="container-fluid docx-container-subnav docx-scrollbar">
  <app-subnav>
    <div left>
      <ul class="nav navbar-nav">
        <li [ngClass]="{'open':downloadFilter === 'licenses'}">
          <a class="clickable pageable" (click)="downloadFilter = 'licenses'" id="licensesBtn">
            <span *ngIf="windowRef.innerWidth >= 996">Licenses</span>
          </a>
        </li>

        <li [ngClass]="{'open':downloadFilter === 'software'}">
          <a class="clickable pageable" (click)="downloadFilter = 'software'" id="softwareBtn">
            <span *ngIf="windowRef.innerWidth >= 996">Software</span>
          </a>
        </li>

        <li [ngClass]="{'open':downloadFilter === 'packages'}">
          <a class="clickable pageable" (click)="downloadFilter = 'packages'" id="packagesBtn">
            <span *ngIf="windowRef.innerWidth >= 996">Packages</span>
          </a>
        </li>

      </ul>
    </div>
    <div right>

    </div>
  </app-subnav>
  <br />
  <div *ngIf="downloadFilter === 'licenses' && (!availableDownloads || !availableDownloads['Licenses'])" class="none-available">No licenses available at this time.</div>
  <app-panel *ngIf="downloadFilter === 'licenses' && availableDownloads && availableDownloads['Licenses']" [Title]="'Licenses'" [MaximizeControls]="false" class="download-category-panel">
    <span *ngIf="availableDownloads['Licenses'].description">{{availableDownloads['Licenses'].description}}</span>
    <div id="licenses-div" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
      <div class="panel-body">
        <div>
          <dx-data-grid
                  [dataSource]="availableDownloads['Licenses'].files"
                  [masterDetail]="{ enabled: false }"
          >
            <dxo-editing
                    mode="form"
                    [allowUpdating]="false"
                    [allowDeleting]="false"
                    [allowAdding]="false"
            >
            </dxo-editing>

            <dxo-selection mode="single"></dxo-selection>

            <dxi-column sortOrder="asc" dataField="env_name" caption="Name" [width]="40+'%'"></dxi-column>
            <dxi-column dataField="id" caption="Identifier"></dxi-column>
            <dxi-column dataField="seats" caption="User Seats" [width]="140" [alignment]="'center'"></dxi-column>
            <dxi-column dataField="expiration" caption="Expiration Date" [width]="140" [alignment]="'center'"></dxi-column>
            <dxi-column dataField="file_exists" caption="Download" [width]="100" [alignment]="'center'" cellTemplate="licTemplate">
              <div *dxTemplate="let row of 'licTemplate'">
                <a [href]="baseUrl + 'getlicense/?licId=' + encodeToUri(row.data.id) + '&acctId=' + authService.accountData.uriEncodedId + '&token=' + encodeToUri(authService.authToken)">
                  <app-icon *ngIf="row.data.file_exists === true" class="license-download-icon" [IconName]="ConfigService.spConstants.icons.download.icon" [IconType]="ConfigService.spConstants.icons.download.type"></app-icon>
                </a>
                <span *ngIf="row.data.file_exists === false" class="download-unavailable" style="color: lightgray;">Unavailable</span>
              </div>
            </dxi-column>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </app-panel>

  <div *ngIf="downloadFilter === 'software'">
    <div *ngIf="!downloadCategories || downloadCategories.length === 0" class="none-available">No downloads available at this time.</div>
    <app-panel *ngFor="let dldCat of downloadCategories" [Title]="dldCat" [MaximizeControls]="false" class="download-category-panel">
      <span *ngIf="availableDownloads[dldCat] && availableDownloads[dldCat].description">{{availableDownloads[dldCat].description}}</span>
      <div id="downloads-div" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
        <div class="panel-body">
          <div>
            <dx-data-grid
                    [dataSource]="availableDownloads[dldCat].files"
                    [masterDetail]="{ enabled: false }"
            >
              <dxo-editing
                      mode="form"
                      [allowUpdating]="false"
                      [allowDeleting]="false"
                      [allowAdding]="false"
              >
              </dxo-editing>

              <dxo-selection mode="single"></dxo-selection>

              <dxi-column sortOrder="asc" dataField="name" caption="Name"></dxi-column>
              <dxi-column dataField="size" caption="Size" [width]="140" [alignment]="'center'"></dxi-column>
              <dxi-column dataField="date" caption="Date" [width]="140" [alignment]="'center'"></dxi-column>
              <dxi-column dataField="url" caption="Download" [width]="100" [alignment]="'center'" cellTemplate="linkTemplate">
                <div *dxTemplate="let row of 'linkTemplate'">
                  <a *ngIf="row.data.url && row.data.url.length > 0" [href]="row.data.url" target="_blank"><app-icon class="license-download-icon" [IconName]="ConfigService.spConstants.icons.download.icon" [IconType]="ConfigService.spConstants.icons.download.type"></app-icon></a>
                  <span *ngIf="!row.data.url || row.data.url.length === 0" class="download-unavailable" style="color: lightgray;">Unavailable</span>
                </div>
              </dxi-column>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </app-panel>
  </div>

  <div *ngIf="downloadFilter === 'packages' && (!availableDownloads || !availableDownloads['Packages'])" class="none-available">No packages available at this time.</div>
  <app-panel *ngIf="downloadFilter === 'packages' && availableDownloads && availableDownloads['Packages']" [Title]="'Packages'" [MaximizeControls]="false" class="download-category-panel">
    <span *ngIf="availableDownloads['Packages'].description">{{availableDownloads['Packages'].description}}</span>
    <div id="packages-div" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
      <div class="panel-body">
        <div>
          <dx-data-grid
                  [dataSource]="availableDownloads['Packages'].files"
                  [masterDetail]="{ enabled: false }"
          >
            <dxo-editing
                    mode="form"
                    [allowUpdating]="false"
                    [allowDeleting]="false"
                    [allowAdding]="false"
            >
            </dxo-editing>

            <dxo-selection mode="single"></dxo-selection>

            <dxi-column dataField="name" caption="Name"></dxi-column>
            <dxi-column dataField="version" caption="Version" [width]="140" [alignment]="'center'"></dxi-column>
            <dxi-column dataField="date" caption="Date" [width]="140" [alignment]="'center'"></dxi-column>
            <dxi-column dataField="size" caption="Size" [width]="140" [alignment]="'center'"></dxi-column>
            <dxi-column dataField="file_exists" caption="Metadata" [width]="100" [alignment]="'center'" cellTemplate="pkgMetaTemplate">
              <div *dxTemplate="let row of 'pkgMetaTemplate'">
                <a [href]="baseUrl + 'getpackage/?pkgId=' + encodeToUri(row.data.name) + '&pkgVers=' + encodeToUri(row.data.version) + '&data=meta&acctId=' + authService.accountData.uriEncodedId + '&token=' + encodeToUri(authService.authToken)">
                  <app-icon *ngIf="row.data.file_exists === true" class="license-download-icon" [IconName]="ConfigService.spConstants.icons.download.icon" [IconType]="ConfigService.spConstants.icons.download.type"></app-icon>
                </a>
                <span *ngIf="row.data.file_exists === false" class="download-unavailable" style="color: lightgray;">Unavailable</span>
              </div>
            </dxi-column>
            <dxi-column dataField="file_exists" caption="Binary" [width]="100" [alignment]="'center'" cellTemplate="pkgBinTemplate">
              <div *dxTemplate="let row of 'pkgBinTemplate'">
                <a [href]="baseUrl + 'getpackage/?pkgId=' + encodeToUri(row.data.name) + '&pkgVers=' + encodeToUri(row.data.version) + '&data=bin&acctId=' + authService.accountData.uriEncodedId + '&token=' + encodeToUri(authService.authToken)">
                  <app-icon *ngIf="row.data.file_exists === true" class="license-download-icon" [IconName]="ConfigService.spConstants.icons.download.icon" [IconType]="ConfigService.spConstants.icons.download.type"></app-icon>
                </a>
                <span *ngIf="row.data.file_exists === false" class="download-unavailable" style="color: lightgray;">Unavailable</span>
              </div>
            </dxi-column>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </app-panel>

</div>