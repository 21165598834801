<!-- Modal -->
<div class="modal fade docx-scrollbar" [attr.id]="modalId" role="dialog" data-backdrop="static">
  <div class="modal-dialog {{windowRef.innerWidth <= 767 ? 'smaller-size' : 'larger-size'}}" [ngClass]="fullScreen ? 'full-screen-modal' : 'normal-modal'">
    <!-- Modal content-->
    <div class="modal-content custom-modal-content" [ngClass]="[fullScreen ? 'full-screen-modal' : 'normal-modal',noPadding ? 'modalWithoutPadding' : '']">

      <div class="modal-header theme-{{theme}}" [ngClass]="fullScreen ? 'full-screen-modal' : 'normal-modal'">
        <button type="button" class="close close-modal-btn" data-dismiss="modal" *ngIf="!disableClose" (click)="modalClosedClicked()">
          <app-icon [IconName]="constants.icons.close.icon" [IconType]="constants.icons.close.type"></app-icon>
          Close
        </button>
        <button data-dismiss="modal" class="hidden-btn-dismiss"></button>

        <button *ngIf="btnConfig" type="button" class="modal-menu-btn modal-menu-btn-custom modal-menu-btn-{{btnConfig.title}}" (click)="sendOutValue()">
          <app-icon *ngIf="btnConfig.hasOwnProperty('icon')" [IconName]="btnConfig.icon.icon" [IconType]="btnConfig.icon.type"></app-icon> {{btnConfig.text}}
        </button>

        <img src="../assets/images/logo-collapsed@2x.png" *ngIf="icon.icon === 'docxonomy'" class="docxonomy-modal-logo">

        <h4 class="modal-title">
            <app-icon [IconName]="icon.icon" [IconType]="icon.type" *ngIf="icon.icon !== 'docxonomy'"></app-icon>
          {{title | truncate : modalTitleTruncate : '...' }}
        </h4>
      </div>
      <div class="modal-body docx-scrollbar {{windowRef.innerWidth <= 767 ? 'smaller-size' : 'larger-size'}}" [ngClass]="[fullScreen ? 'full-screen-modal' : 'normal-modal',noPadding ? 'modalWithoutPadding' : '']">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
