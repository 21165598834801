import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as UAParser from 'ua-parser-js';

/** UAParser parses the user agent into useful data for us */
let newParser = new UAParser();

/** parsed user agent */
let userAgentParsed = newParser.getResult();

/** name of the browser being used. EX. Chrome, Safari */
let browserName = userAgentParsed.browser.name;

/** version of the browser being used. EX. 61, 10 */
let browserVersion = userAgentParsed.browser.major;

/** boolean to check whether or not this browser will be allowed */
let browserVersionAllowed:boolean = false;

/** the current browsers we support. key is the browser name, value is the browser version we support */
let minBrowserSupport = {
  Chrome: 50,
  Safari: 9,
  "Mobile Safari": 9,
  Edge: 15,
  Firefox: 48,
  Opera: 42,
  Chromium: 74
};

//does a check through all of the browsers and their compatible version numbers and makes sure that the current users browser can work with our system
/** @ignore */
let browserKeys = Object.keys(minBrowserSupport);

for(let i=0;i<browserKeys.length;i++) {
  //checks the browser name and version
  if(browserName === browserKeys[i] && browserVersion >= minBrowserSupport[browserKeys[i]]) {
    browserVersionAllowed=true;
  }
}


//if the current environment is set to production then enable production mode
if (environment.production && browserVersionAllowed) {
  enableProdMode();
}

//if the version is not allowed
// show the table that shows compatible browser versions properly
//if the version is allowed then bootstrap the app and start loading
if(!browserVersionAllowed) {
  //comment out these next 4 lines to have the documentation work properly
  let table = document.getElementsByClassName("browser-support-table")[0];
  let tableTitle = document.getElementsByClassName("browser-support-table-title")[0];
  table['style']['display'] = 'block';
  tableTitle['style']['display'] = 'block';
}
else {
  platformBrowserDynamic().bootstrapModule(AppModule);
}
