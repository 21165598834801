<!--innerHeight: {{_deviceService.browserWindow.innerHeight}}-->
<!--innerWidth: {{_deviceService.browserWindow.innerWidth}}-->
<!--{{_deviceService._isMobile}}-->
<!--{{_deviceService.device}}-->
<!--{{_deviceService.orientation}}-->
<!--{{_deviceService.portraitAcceptable}}-->
<!--{{_deviceService.landscapeAcceptable}}-->
<div id="main-div" class="main-container orientation-portrait"  *ngIf="(_deviceService.browserWindow.innerHeight >= DeviceService.MIN_BROWSER_HEIGHT) || ((_deviceService._isMobile) && ((_deviceService.orientation === 'portrait') && (_deviceService.portraitAcceptable)) || ((_deviceService.orientation === 'landscape') && (_deviceService.landscapeAcceptable)))">

  <main-header *ngIf="(authService.isLoggedIn && authService.userData && authService.userData.resetPasswordCompleted)"></main-header>
  <router-outlet></router-outlet>

  <app-loading [Loading]="false" [Backdrop]="false"></app-loading>

  <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#timeoutModal" *ngIf="authService.isLoggedIn">Open
    Modal
  </button>

  <app-modal ModalId="timeoutModal" Title="Session Timeout" [Icon]="constants.icons.timeout" (modalClosed)="makeActiveAgain()">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <span>You will be logged out due to inactivity in {{_timeoutService.countdownTimeLeft}} seconds </span>
          <div class="btn btn-default" (click)="makeActiveAgain()">Click to keep working</div>
        </div>
      </div>
    </div>
  </app-modal>

  <app-footer></app-footer>

</div>


<div class="orientation-landscape" *ngIf="((!_deviceService._isMobile) && (_deviceService.browserWindow.innerHeight < DeviceService.MIN_BROWSER_HEIGHT)) || ((_deviceService._isMobile) && ((_deviceService.orientation === 'landscape') && (_deviceService.portraitAcceptable)) && (!_deviceService.landscapeAcceptable))">
    <div class="row loginAndAccountSelectionImg-container">
      <img src="../assets/images/logo@2x.png" class="img-responsive loginAndAccountSelectionImg"/>
    </div>

  <p class="landscape-mode-text">
    The current height of your screen is not supported by our application. Resize or rotate your screen to continue working.
  </p>
</div>

<div class="orientation-landscape" *ngIf="((_deviceService._isMobile) && (!_deviceService.landscapeAcceptable) && (!_deviceService.portraitAcceptable))">
  <div class="row loginAndAccountSelectionImg-container">
    <img src="../assets/images/logo@2x.png" class="img-responsive loginAndAccountSelectionImg"/>
  </div>

  <p class="landscape-mode-text">
    This device screen size is not supported.  Please open on a larger screen.
  </p>
</div>

