import {Component, HostListener, OnInit} from '@angular/core';
import {GeneralUtilService} from '../../_services/helpers/general-util.service';
import {AuthenticationService, LOGOUT_REASON} from "../../_services/functionality/authentication.service";
import {Router} from "@angular/router";


/** subcomponent that encapsulates the main application header */
@Component({
  selector: 'main-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  /** boolean to track whether or not the notification modal is opened */
  notificationModalOpened: boolean = false;

  /** amount to truncate the company name */
  truncateCompanyName: any;

  /** local instance of js window object to be used in the template */
  windowRef: any;

  /** @ignore */
  constructor(public authService: AuthenticationService, public router: Router) {
    this.windowRef = window;
  }

  /** handles truncating the company name if the window is resized */
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.truncateCompanyName = GeneralUtilService.scaleNumberBasedOnWidthOrHeightOfDefaultScreenSize(20,'width');
  }

  /** @ignore */
  async ngOnInit() {
    this.truncateCompanyName = GeneralUtilService.scaleNumberBasedOnWidthOrHeightOfDefaultScreenSize(20,'width');
  }

}
