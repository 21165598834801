import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ConfigService} from '../../_services/helpers/config.service';
import {SupportConstants} from '../../../support_constants';

/** subcomponent that extends bootstrap 3's panel functionality */
@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit {
  /** the title of the panel */
  @Input('Title') title: string;

  /** whether or not to show the maximizing controls */
  @Input('MaximizeControls') maximizeControls: boolean;

  /** specific functionality for the carousel */
  @Input('Carousel') carousel: boolean;

  /** specific functionality for the carousel */
  @Output('OpenCarousel') carouselChange = new EventEmitter<any>();


  /** whether or not the panel can be maximized */
  maximized: boolean = false;

  /** local instance of the platinum constants to be used in the template */
  constants: SupportConstants;

  /** @ignore */
  constructor() {
    this.constants = ConfigService.spConstants;
  }

  /** @ignore */
  ngOnInit() {
  }

  /** toggle making the panel fullscreen */
  toggleFullscren() {
    this.maximized = !this.maximized;
  }

  /** specific functionality for the carousel */
  openImageCarousel() {
    this.carouselChange.emit(this.title);
  }
}
