import { Injectable } from '@angular/core';
import {ErrorWithStatusCode, GeneralUtilService} from '../helpers/general-util.service';
import notify from 'devextreme/ui/notify';
import {HttpErrorResponse} from "@angular/common/http";

/** wrapper around the normal js Error object to handle errors with special logic */
export class IronHttpError extends ErrorWithStatusCode {

  status: number;
  statusText: string;

  /** @ignore */
  constructor(httpErrorResponse: HttpErrorResponse) {

    let errMsg: string = "";

    //Hide the details of forbidden and internal errors:
    if (httpErrorResponse.status === 403) {
      errMsg = "This action is forbidden";
    }
    else if (httpErrorResponse.status === 500) {
      errMsg = "An error occurred on the server";
    }
    else {

      if (httpErrorResponse.error) {

        //IMPORTANT: "observe: 'response' as 'response'" must have been specified in the request options in order for the HttpErrorResponse to contain a useable error
        //"error" property in its message body.
        errMsg = httpErrorResponse.error["error-msg"] || httpErrorResponse.error.error; //Errors from IronServer have an "error-msg" property in their JSON HTTP body
        if (!errMsg) {
          errMsg = httpErrorResponse.error;
        }

        //If the status is zero, then we know the request never reached the server, so we embellish the error message:
        if (httpErrorResponse.status === 0) {
          errMsg = "Failed to communicate with server" + ((errMsg) ? " :: " + errMsg : "");
        }
      }
      else { //try to use the message property as the error message (e.g. - a status of 405 will result in this situation, where error property is null
        errMsg = httpErrorResponse.message;
      }
    }

    //Create a normalized error with the details we need:
    super(new Error(errMsg), httpErrorResponse.status);

    //Typescript workaround as per: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, IronHttpError.prototype);

    this.statusText = httpErrorResponse.statusText;
  }
}

/** this service handles all logging throughout the application. This includes all global error handling as well as messaging to the user.
 */
@Injectable()
export class LoggingService {
  /** the log number that is allowed in local storage */
  static amountOfLogsHeldInLocalStorage: number;

  /** the logging path to put as a key in local storage */
  static docxonomyLoggingPath: string;

  /** initialization date of the startup of the application (technically the logging service) */
  static initializedDate: number;

  /** the log number that is allowed in local storage */
  static proceed_with_logging: boolean = true;

  /** the entire message queue */
  static toastMessageQueue: any = [];

  /** an identifier for the current toast that is being shown to the user */
  static currentToastTimeoutId:any;

  /** the default display time for a toast */
  static toastDefaultDisplayTime: number = 6000;

  /** the default padding in between showing different toast messages */
  static toastDisplayTimePadding: number = 1000;

  /** a boolean to register whether or not the toast should be visible */
  static toastVisible: boolean = false;

  /**
   * @ignore
   */
  constructor() { }

  /** master function to send a message to the user as a notification at the bottom of the screen. this function handles the queuing for the messages as well.
   * @param message - the message that should be displayed
   * @param type - what type of notification should be set. see https://notifyjs.jpillora.com/ for types.
   * @param time - how long the message should be visible for
   */
  static showMessage(message: string, type: string, time: number | 'default' | '') {
    let toastDisplayTime: number;

    //if the function call is not empty then make a new toast message in the queue
    //if it is empty then this function was called because there is another item in the queue we need to check for
    if (!(message === '' && type === '' && time === '')) {
      LoggingService.toastMessageQueue.push({message: message, type: type, displayTime: time});
    }

    //if the queue has an item in it
    //if the toast is not already visible
    //run the process of starting a new message
    //else do another check in 100ms
    if (LoggingService.toastMessageQueue.length > 0) {
      if(LoggingService.currentToastTimeoutId) {
        LoggingService.toastVisible = false;
        clearTimeout(LoggingService.currentToastTimeoutId);
        LoggingService.currentToastTimeoutId = null;
      }
      //sets the toast time
      if (LoggingService.toastMessageQueue[0].displayTime === 'default') {
        toastDisplayTime = LoggingService.toastDefaultDisplayTime;
      }
      else {
        toastDisplayTime = LoggingService.toastMessageQueue[0].displayTime;
      }

      //sets the toast to be visible to the user
      LoggingService.toastVisible = true;

      //set up a new toast notification with the correct message, type and display time for the current toast
      notify({
        message: LoggingService.toastMessageQueue[0].message,
        clickToHide: true
      }, LoggingService.toastMessageQueue[0].type, toastDisplayTime);  //Using DevExtreme Toast


      //clean the message that just got created off of the queue
      LoggingService.toastMessageQueue.splice(0, 1);

      //after a determined amount of time
      //set the popup to not be visible which should trigger another toast to be shown from docheck
      LoggingService.currentToastTimeoutId = setTimeout(() => {
        LoggingService.toastVisible = false;
        LoggingService.currentToastTimeoutId = null;
      }, toastDisplayTime + LoggingService.toastDisplayTimePadding);
    }
  }

  /** master logging function that is called throughout the application.
   * @param code - the code is related to an error that is either in platinum constants
   * @param context - an object that mimics the iron server context
   * @param err - the error that occurred. it doesnt have to be an error
   * @param consoleError - determines whether or not the error should be consoled
   * @param showMessage - determines whether or not the user is going to get a message
   */
  static docxonomyLogging(code: number, context, err, consoleError: boolean, showMessage: boolean) {

    if (err) {

      if (consoleError) {
        console.error(err);
      }

      if (showMessage) {
        LoggingService.showMessage(err.message, 'error', 10000);
      }
    }
  }

  /** helper function for the docxonomylogging function that sets up a new log in localstorage
   * @param {Object} log - the log that needs to be added to local storage
   */
  static setupNewLogEntry(log: Object) {
    let currLogObj;
    try {
      //gets the larger log object kept in local storage
      currLogObj = LoggingService.getLogListFromLocalStorage();

      //manages putting the new log object into the master list
      currLogObj = LoggingService.manageMasterLogObject(currLogObj, log);

      //set the new local storage variable
      LoggingService.setLogToLocalStorage(currLogObj);
    }
    catch (err) {
      console.error(err);
    }
  }

  /** helper function for the docxonomylogging function that gets the log list from local storage
   */
  static getLogListFromLocalStorage(): Object[] {
    //get the loging obj from the local storage and reparses it so that it can be used as a js object
    try {
      let objFromLocalStorage: any = JSON.parse(localStorage.getItem(LoggingService.docxonomyLoggingPath));

      //if the object we got from local storage is null or undefined then create it properly
      if (!objFromLocalStorage) {
        objFromLocalStorage = [];
      }

      //return the object found in local storage or the new structure for the logging master list
      return objFromLocalStorage;
    }
    catch (err) {
      throw err;
    }
  }

  /** helper function for the docxonomylogging function that manages adding the log object to the master list
   */
  static manageMasterLogObject(logObject: Object[], log: Object) {
    try {
      //checks to see how many log entries there are
      // if there are more than the specified amount that we are trying to keep, get rid of the first entry and append the new log onto the end
      // otherwise push the log onto the list

      if (logObject.length >= LoggingService.amountOfLogsHeldInLocalStorage) {
        logObject.pop();
      }

      logObject.push(log);
      return logObject;
    }
    catch (err) {
      throw err;
    }
  }

  /** helper function for the docxonomylogging function that tests the size of local storage to make sure that we don't add too much to local storage memory wise
   */
  static testSizeOfLocalStorage() {
    try {
      let localStorageAmt: number = 0;
      for (let key in localStorage) if (localStorage[key]) {
        localStorageAmt += Number.parseInt(GeneralUtilService.getMemorySizeOfString(JSON.stringify(localStorage[key]), true));
      }

      //if we exceed the max amount that local storage can contain then throw an error so that we don't break everything
      return localStorageAmt < GeneralUtilService.LocalStorage.size_limit;
    }
    catch (err) {
      err.message = "Problem testing the size of local storage" + err.message;
      throw err;
    }
  }

  /** handles setting the log to local storage
   */
  static setLogToLocalStorage(logObj: Object[]) {
    try {
      //before it sets the value to local storage it tests to make sure that local storage won't explode
      if(LoggingService.testSizeOfLocalStorage()) {
        //sets the loging obj to the local storage stringified so it can easily be reaccessed later
        localStorage.setItem(LoggingService.docxonomyLoggingPath, JSON.stringify(logObj));
      }
    }
    catch (err) {
      throw err;
    }
  }

  /** helper function for the docxonomylogging function that sets up a new log in localstorage
   * @param {Object} context - the context to be cleaned up
   * @return {Object}
   */
  static cleanContextForLogging(context: Object): Object {
    //list of values that should exist in the context variable
    let contextVars: string[] = ['query', 'data_guid', 'hash', 'operation', 'subcomponent'];

    //array to hold all of the indeces of the array that we already have
    let foundKeys = [];

    //finds all of the context variables that we already have
    let keys = Object.keys(context);
    for (let i = 0; i < keys.length; i++) {

      for (let h = 0; h < contextVars.length; h++) {
        if (keys[i] === contextVars[h]) {
          foundKeys.push(h)
        }
      }
    }

    //sorts to make sure that the greatest item in the array gets deleted first (not messing up the order )
    foundKeys.sort((a, b) => {
      return b - a;
    });


    //goes through the keys we found to exist already and programmatically slices them
    for (let k = contextVars.length; k >= 0; k--) {
      for (let m = 0; m < foundKeys.length; m++) {
        if (k === foundKeys[m]) {
          contextVars.splice(k, k + 1);
        }
      }
    }

    //goes through the remaining values that we know need to be given an empty spot in the context
    for (let f = 0; f < contextVars.length; f++) {
      context[contextVars[f]] = '';
    }

    //returns the cleaned up context back to be logged
    return context;
  }
}
