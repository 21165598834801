<div class="panel panel-default">
  <div class="panel-heading">
          <span class="panel-title-container">
            <h3 class="panel-title">{{title}}</h3>
          </span>
    <span class="panel-btn-list">
            <i class="fas fa-expand clickable" aria-hidden="true" *ngIf="carousel" (click)="openImageCarousel()"></i>
            <i class="fas fa-minus clickable" aria-hidden="true" *ngIf="maximized && maximizeControls"
               (click)="toggleFullscren()"></i>
            <app-icon [IconType]="constants.icons.add.type" [IconName]="constants.icons.add.icon" (onIconClicked)="toggleFullscren()" *ngIf="!maximized && maximizeControls" Classes="clickable"></app-icon>
          </span>
  </div>


  <div class="panel-body">
    <div class="container panel-body-container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>

