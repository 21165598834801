import { Injectable } from '@angular/core';
import {LoggingService} from './logging.service';
import {ConfigService} from '../helpers/config.service';
import {AuthenticationService, LOGOUT_REASON} from "./authentication.service";
import {GeneralUtilService} from "../helpers/general-util.service";

/** Handles the timeout functionality throughout the application.
 */
@Injectable()
export class TimeoutService {
  /** timeout identifier */
  public forcedLogoutTimerID: any;

  /** idle identifier */
  public idleTimerID: any;

  public countdownTimerID: any;

  public countdownTimeLeft: number;

  /** a boolean that tracks whether or not the idle popup should be opened. we also use this boolean to dynamically click the button that is a control for the modals */
  public isIdlePopupOpen: boolean;

  /** a boolean to determine whether or not we should make the user active or not */
  public makeActive: boolean;

  /**
   * @ignore
   */
  constructor(public authService: AuthenticationService) {

    this.isIdlePopupOpen = false;
    this.makeActive = false;
  }

  /** create the master setTimeout that handles the actual timeout. We save the id of the timeout so that we can remove it in the future if we need. This function is repeated when an event that breaks the idle is fired.
   * @public
   */
  public setupTimeout() {

    //create the setTimeout to deal with timing out and forcing logout after BOTH the idle warning time and the additional forced timeout has expired:
    this.forcedLogoutTimerID = setTimeout(()=> {

      this.isIdlePopupOpen = false;



      //Once timed out disable the modal screen
      document.getElementById('openModalButton').click();

      //Force logout:
      this.authService.logoutAndRoute(LOGOUT_REASON.TIMEOUT);

    }, ConfigService.spConstants.general.idleWarningLengthInMilliseconds + ConfigService.spConstants.general.forcedTimeoutAfterIdleWarningInMilliseconds);

    //create the setTimeout to show the idle warning popup after the idle time limit has been reached:
    this.idleTimerID = setTimeout(()=> {



      //If the idle warning popup is not already shown, open it:
      if (!this.isIdlePopupOpen) {
        this.isIdlePopupOpen = true;
        document.getElementById('openModalButton').click();
      }

      this.countdownTimeLeft = ConfigService.spConstants.general.forcedTimeoutAfterIdleWarningInMilliseconds / 1000;
      this.countdownTimerID = setInterval(() => {

        this.countdownTimeLeft--;

      }, 1000);

    }, ConfigService.spConstants.general.idleWarningLengthInMilliseconds);
  }

  /** reset the timeouts and stores the last active time in local storage */
  public clearTimeoutWithinApplication() {



    //set the last active flag to be the most recent active time
    localStorage.setItem(GeneralUtilService.LocalStorage.lastActive,new Date().getTime().toString());

    clearTimeout(this.forcedLogoutTimerID);
    clearTimeout(this.idleTimerID);
    if (this.countdownTimerID) clearInterval(this.countdownTimerID);

    this.forcedLogoutTimerID = null;
    this.idleTimerID = null;
    this.countdownTimerID = null;
  }

  /** reset/renew the timeouts.  Also, if the idle warning popup is open, close it:   */
  public resetTimeoutWithinApplication() {

    //only do these things if the user is logged in
    if(this.authService.isLoggedIn ) {

      let setPopupFalse: boolean;

      //if the user is active again then hide the popup and make this flag false
      if(this.makeActive) {
        document.getElementById('openModalButton').click();
        setPopupFalse = true;
        this.makeActive = false;
      }

      //this means that the popup was just clicked and we should make the user active again
      if(this.isIdlePopupOpen && !this.makeActive && setPopupFalse) {
        this.isIdlePopupOpen = false;
      }

      //If the idle popup is closed, clear the old timers and setup new:
      //NOTE: If the user "becomes active" again because they moved the cursor while the idle popup was open, but they
      //they don't take action to close it, we should not reset the timers.
      if(!this.isIdlePopupOpen) {
        this.clearTimeoutWithinApplication();
        this.setupTimeout();
      }
    }
    //The user is not logged in but we have timers, so remove/clear them:
    else if((this.forcedLogoutTimerID || this.idleTimerID) && !this.isIdlePopupOpen) {

      this.clearTimeoutWithinApplication();
    }
  }
}
