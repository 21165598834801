import {Component, HostListener, OnInit} from '@angular/core';
import {DatePipe, TitleCasePipe} from "@angular/common";
import {ConfigService} from '../_services/helpers/config.service';
import {SupportConstants} from '../../support_constants';
import {LoggingService} from '../_services/functionality/logging.service';

import {AuthenticationService} from "../_services/functionality/authentication.service";
import {environment} from "../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpResponse} from '@angular/common/http';
import {throwError as observableThrowError} from "rxjs/internal/observable/throwError";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

/** Main search page. handles before search, after search is done, and all search results. */
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
  providers: [TitleCasePipe, DatePipe]
})

export class LandingComponent implements OnInit {

  ConfigService = ConfigService;

  /** local instance of the platinum constants to be used in the template */
  constants: SupportConstants;

  /** local instance of the js object window to be used in the template */
  windowRef: Window;

  messages: any;

  /** @ignore */
  constructor(public authService: AuthenticationService, public http: HttpClient, public sanitizer: DomSanitizer) {

    this.constants = ConfigService.spConstants;
    this.windowRef = window;
  }

  /** @ignore */
  async ngOnInit() {
    let resp = await this.callLandingMessagesService();
    if(resp && resp.messages && typeof resp.messages === 'object' && Object.keys(resp.messages).length > 0)
      this.messages = resp.messages;
    else
      this.messages = undefined;
  }

  private callLandingMessagesService(): Promise<any> {

    if(!this.authService.accountData.id || this.authService.accountData.id === "")
      throw new Error("Account is not set. Cannot get messages.");

    // Get a account data after authorization:
    const url = environment.webURLBase + 'messages';

    return this.http.get<any>(url, this.authService.createRequestOptions())
        .pipe(
            map<HttpResponse<any>, any>(response => response.body),
            catchError((err) => {

              return observableThrowError(err);
            })
        ).toPromise();
  }
}
