import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthGuard} from "./auth.guard";
import {AuthenticationService} from "../_services/functionality/authentication.service";

@Injectable()
export class AdminGuard extends AuthGuard {

  constructor(public authService: AuthenticationService, public router: Router) {
    super(authService, router);
  }

  //Called by the angular router to check if the requested route can be allowed.  A true allows the route, a false denies it.
  //If the boolean is wrapped in an Observable or Promise, the router will wait for the result to come through the Observable
  //before proceeding to allow or deny.
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    if (await super.canActivate(next, state)) {

      //If the user is truly an admin, allow the route:
      if (this.authService.userData && this.authService.userData.role && this.authService.userData.role === 'admin') {

        return true;
      } else { // send the user to the landing page as if this was an unknown route

        this.router.navigate(['/landing']);

        return false;
      }
    }

    return false;
  }
}
