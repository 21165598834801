import {Routes, RouterModule} from '@angular/router';

import {LoginComponent} from './login/login.component';
import {PasswordResetComponent} from './_subcomponents/password-reset/password-reset.component';
import {LandingComponent} from './landing/landing.component';
import {DocumentationComponent} from './documentation/documentation.component';
import {DownloadsComponent} from './downloads/downloads.component';
import {ProfileComponent} from './profile/profile.component';
import {AdministrationComponent} from './administration/administration.component';
import {AuthGuard} from "./_guards/auth.guard";
import {AdminGuard} from "./_guards/admin.guard";

const appRoutes: Routes = [
  {path: '', component: LandingComponent, canActivate: [AuthGuard]},
  {path: 'documentation', component: DocumentationComponent, canActivate: [AuthGuard]},
  {path: 'downloads', component: DownloadsComponent, canActivate: [AuthGuard]},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
  {path: 'admin', component: AdministrationComponent, canActivate: [AdminGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'password-reset', component: PasswordResetComponent, canActivate: [AuthGuard]},

  // otherwise redirect to home
  {path: '**', redirectTo: ''}
];

/** declares the routing object to pull in for the app.module */
export const routing = RouterModule.forRoot(appRoutes);
