import { Injectable } from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";

/** declares the function download from external library */
declare function download(file,name,type): any;

/** the structure created when we parse a url */
export interface ParsedURL {

  /** the page from the url */
  page: string;

  /** the parameters in the url */
  params: any;

  /** the complete original url */
  original: string;
}

export class ErrorWithStatusCode extends Error {

  status: number;

  /** @ignore */
  constructor(err: Error, statusCode: number = -1) {

    super(err.message);

    //Typescript workaround as per: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, ErrorWithStatusCode.prototype);

    this.status = statusCode;
  }
}

export class MissingPropertyException extends ErrorWithStatusCode {

  propertyName: string;

  /** @ignore */
  constructor(propertyName: string, err: Error | string, statusCode: number = -1) {

    if (typeof err === "string") {
      super(new Error(err), statusCode);
    }
    else {
      super(err, statusCode);
    }

    //Typescript workaround as per: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, MissingPropertyException.prototype);

    this.propertyName = propertyName;
  }
}

/** A general service with helper functions for platinum */
@Injectable()
export class GeneralUtilService {

  static illegalFileNameCharsPattern = RegExp(/[^a-zA-Z0-9-&. ,_$(){}\[\]\^#@!~]/, "g");
  static dotPattern = RegExp(/\./, "g");
  static preOrPostDotPattern = RegExp(/^\s*\.+\s*|\s*\.+\s*$/, "g");

  public static DEFAULT_MSG = "~~DEFAULT_MSG~~";

  /** helper function to strip html and get just text.
   * @static
   * Safely trim a string.  This method cuts down on the code necessary to deal with
   * strings that might be null.  If the value of the string really is null, you have
   * the option to return a different string in its place.
   */
  static safeTrim(theValue, nullReplacement, convertToString) {

    //Set default null replacement to null if a valid string is not provided:
    //nullReplacement = nullReplacement || null - with this syntax, empty string loses to null, so we can't use it.
    if (nullReplacement === undefined) nullReplacement = null;

    //By default, we do NOT convert to string:
    if (convertToString === undefined) convertToString = false;

    if (theValue != null) {
      if (typeof theValue === "string") {
        theValue = theValue.trim();
      }
      else if (convertToString) {
        theValue = theValue.toString().trim();
      }
      else {
        //Do nothing.  It is not a string and it was not specified to convert it, so just return it
      }
    }
    else {
      theValue = nullReplacement;
    }

    return theValue;
  }

  /** helper function to strip html and get just text.
   * @static
   */
  static stripHTMl(html: string): string {
    //create a new html element
    let tmp = document.createElement("DIV");
    //get the inner html element
    tmp.innerHTML = html;

    //if there is a text content then give us that back,
    // otherwise give us the inner text of the element
    // otherwise its empty and return nothing
    return tmp.textContent || tmp.innerText || "";
  }

  /** adds the active phrase class to elements of a specific class
   * @static
   */
  static addActivePhraseClassToElements(elements:Element[]) {

    elements.forEach(element => {
      element.className = (element.className + ' active-phrase').trim();
    });
  }

  /** constants used when interacting with mongo
   * @static
   */
  static MongoConstants = {
    VALUE_NOT_EXIST: "$notExists",
    VALUE_CONTAINS: "$text",
    VALUE_EQUALS: "$eq"
  };

  static LocalStorage = {
    "logging" : "docxonomy_ui_logging",
    "email" : "docx_email",
    "account_id" : "docx_accountid",
    "account_name" : "docx_accountname",
    "size_limit" : 5000000,
    "lastActive" : "docx_lastactive",
    "token" : "docx_token"
  };

  /** @ignore */
  constructor() { }

  /** takes in a phone number and returns a formatted version of that number
   * @static
   */
  static formatNumberIntoPhoneNumber(phone_number: string | number) {
    if(typeof phone_number === 'number') {
      phone_number = phone_number.toString();
    }

    return phone_number.substr(0,3) + '-' + phone_number.substr(3,3) + '-' + phone_number.substr(6,4);
  }

  /** given an error, try to find the stack trace
   * @static
   */
  static getStackTrace(err) {
    return err.stack || /*old opera*/ err.stacktrace || ( /*IE11*/ console.trace ? console.trace() : "no stack info");
  }

  /** given a default value for the 1280 x 800 screen, attempts to scale it for other sizes
   * @static
   @returns {number}
   */
  static scaleNumberBasedOnWidthOrHeightOfDefaultScreenSize(defaultValue: number, widthOrHeight: 'width' | 'height'): number {
    let width = 1280,
      height = 800,
      valueThatViewStopsAdjusting = 767,
      actualValue;


    if (widthOrHeight === 'width') {

      let valueToUseForMax = window.innerWidth > valueThatViewStopsAdjusting ? window.innerWidth : valueThatViewStopsAdjusting;
      actualValue = Math.floor(((defaultValue * valueToUseForMax) / width) * 0.88);
    }
    else if (widthOrHeight === 'height') {
      actualValue = Math.floor(((defaultValue * window.outerHeight) / height) * 0.88);
    }

    return actualValue;
  }

  /** mimics the back button getting clicked
   * @static
   */
  static backClicked() {
    window.history.go(-1);
  }

  /** generic copy function. takes in a string and goes through the process for the user to copy the string. Creates a hidden textarea with that string and then either uses the normal select() function or if on an apple device, it has to use special logic documented @https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
   * @static
   @returns {Promise}
   */
  static copy(value: string): Promise<string> {
    return new Promise((resolve, reject) => {
      //https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
      try{
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);

        try {
          if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            let oldContentEditable = selBox.contentEditable,
              oldReadOnly = selBox.readOnly,
              range = document.createRange();

            selBox['contenteditable'] = true;
            selBox['readonly'] = false;
            range.selectNodeContents(selBox);

            let s = window.getSelection();
            s.removeAllRanges();
            s.addRange(range);

            selBox.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

            selBox.contentEditable = oldContentEditable;
            selBox.readOnly = oldReadOnly;
          }
          else {
            selBox.select();
          }


          document.execCommand('copy');
        }
        finally {
          document.body.removeChild(selBox);
        }

        resolve('');
      }
      catch(err) {
        reject(err);
      }
    });
  }

  /** takes in a string and lowercases the first letter
   * @static
   @returns {string}
   */
  static lowercaseFirstLetterOfWord(text: string): string {
    return text.substring(0, 1).toLowerCase() + text.substring(1, text.length);
  }

  /** reverses a different we have that creates a "title case".
   * @static
   @returns {string}
   */
  static undoTitleCase(text: string): string {
    //if the text has a space then do the lowercasing on each split part of the word
    if(text.indexOf(" ") >= 0) {
      let words = text.split(" ");

      words = words.map((word)=> {
        return GeneralUtilService.lowercaseFirstLetterOfWord(word);
      });

      text = words.join(" ");
    }
    //otherwise just do the lowercase
    else {
      text = GeneralUtilService.lowercaseFirstLetterOfWord(text);
    }
    return text;
  }

  /** converts an object into an array of its values
   * @static
   @returns {any[]}
   */
  static getObjectValues(inputObject: Object, key_property_name: string = "", value_property_name: string = "value"): any[] {

    if (inputObject) {

      //Loop over the keys in the object:
      return Object.keys(inputObject).map((key) => {

        //Check if its been requested that the resulting array of objects should have the key itself added
        // into the objects in the array, as a property:
        if (key_property_name.length > 0) {

          let ret;

          //If the value at this key in the input object is an object itself, then simply retrieve it:
          if (typeof inputObject[key] === "object") {

            //Get the value of the object for this key:
            ret = inputObject[key];

          }
          else { //the value at this key in the input object is a primitive, so create a new object to house it and
            //use the value property name provided (or defaulted):

            // ret = Object.create(null); //IMPORTANT NOTE: Creating the object this way seems to mess up Dev Extreme when these values
            // are used in drop-down lists, getting this error: TypeError: Cannot convert undefined or null to object
            ret = {};
            ret[value_property_name] = inputObject[key];
          }

          //Add the key into the object before we return/add it into the resulting array:
          ret[key_property_name] = key;

          return ret;
        }
        else { //No request was made to add the key itself into the resulting array of objects, so simply return/add
          // it into the resulting array as is:

          return inputObject[key];
        }

      });
    }
    else {
      return [];
    }
  }

  /** converts an array into an object keyed by a property of the array element objects with its values being the objects themselves
   * @static
   @returns {any[]}
   */
  static getObjectFromArray(objArray: Object[], keyProperty: string): any {

    let outputObj = Object.create(null);

    if (objArray) {

      //Reduce the array elements, accumulating them into one object:
      outputObj = objArray.reduce((accumulator, obj) => {
        accumulator[obj[keyProperty]] = obj;
        return accumulator;
      }, Object.create(null));
    }

    return outputObj
  }

  //Recursively traverse a "dotted" property path, down to the innermost property and return its value:
  /**
   DEVELOPER NOTE: A nearly identical java version of this, and related methods exists in JavaHelper.  Bug
   fixes / improvements made here may also be needed there. *****
   */
  private static getDottedProperty<T>(dataHolder: any, originalPropertyName: string, propertyParts: string[], partsIndex: number): T {

    //Check if we've been provided property parts or if this is the first time this method has been called:
    if ((propertyParts == null) || (propertyParts.length == 0)) {

      //If we've got no property parts, throw an exception, because that means we weren't give a valid original property name:
      originalPropertyName = this.safeTrim(originalPropertyName, "", true);
      if (originalPropertyName.length === 0) {
        throw new Error("No property name was specified.");
      }

      //Split the property name into parts:
      propertyParts = originalPropertyName.split(this.dotPattern);
      partsIndex = 0;
    }

    //Get the next (or first) property from the map or list:
    let partName = propertyParts[partsIndex].trim();
    let result = null;



    //Assume that we will need to continue recursive calls:
    let recurse = true;

    if (!dataHolder) {

      result = dataHolder;
      recurse = false;
    }
    else if (dataHolder instanceof Array) { //Check if the data is a list:

      //A non-numeric part name means the list should be traversed and its <part name> property should be returned for each list element
      //A numeric part name means that the value of the list at the indicated index should be returned
      const partNameAsNum: number = Number.parseInt(partName);
      if (Number.isNaN(partNameAsNum)) {

        //Get the data holder as a list of values and create an empty output values list:
        const tempOutputList: any[] = [];

        //Iterate over the list:
        for (let i = 0; i < dataHolder.length; i++) {

          //Get the next item from the list and recursively resolve it:
          //NOTE: We do not add null or empty strings.
          const item = dataHolder[i];

          tempOutputList.push(this.getDottedProperty(item, originalPropertyName, propertyParts, partsIndex));
        }

        //Set the output list as the result, whether it was populated via recursion of nested lists above or through simple map properties:
        result = tempOutputList;
      }
      else { //we have a numeric list index, so simply get the value listed at that index from the list:

        result = dataHolder[partNameAsNum];
      }
    }
    // We were given a map, so get its part name:
    else if (typeof dataHolder === "object") {

      result = dataHolder[partName]; //If the value is missing, result is null and the caller can do whatever is appropriate
    }
    else { // we were given something else:

      if (typeof dataHolder === "string") {
        result = dataHolder.trim();
      }
      else {
        result = dataHolder; //Would only reach here, if the calling routine provided a dataHolder value that was something other than a map or list or string, so just return it.
      }

      //Switch the recurse flag to false so we stop recursion now that we've reached a non-list and non-map.
      recurse = false;
    }

    //Recursively get the next property in the path unless the recurse flag got changed above or the result is null or we've reached the end of the property path:
    //console.debug("result type: " + (typeof result), "partsIndex:" + partsIndex + " partName: " + partName)
    if ((recurse) && (result != null) && (partsIndex < (propertyParts.length - 1))) {
      return this.getDottedProperty<T>(result, originalPropertyName, propertyParts, partsIndex + 1);
    }
    else { //otherwise, return our final value:
      return <T>result;
    }
  }

  //Traverse a dotted path and return the value at that path within the object:
  //IMPORTANT NOTE: The presence of a required error message (anything other than null), trumps the default value.  Thus, if a required error message is provided, it is assumed that
  //the required error message should be thrown and the default value does not apply.  To use the default value, set the required error message to null.
  public static getProperty<T>(map: any, propertyName: string, defaultValue: T, errorCode?: number, requiredErrorMessage?: string, traverse: boolean = true): T {

    /*
    NOTE: Gather a data point or value list from sub-objects, based on a potentially "dotted" path that should be
    traversed.  Default values can be provided if a value is missing.  Alternatively, an error message can be provided,
    indicating that an error should be thrown if the value is missing.  This method is also safe to call without check
    if the main data map is null or undefined.

    EXAMPLES: Given the following data map...
     {
      financial: {
       invoices: [
         {
           info: {
             invoice_number: 123343,
             invoice_date: "March 23, 2020",
             amount: 56.87
            },
           line_items: []
         },
         {
           info: {
             invoice_number: 9656473,
             invoice_date: "December 26, 2021",
             amount: 346.87
            },
           line_items: []
         }
      ]
     }
    }

    Example 1: A configured data path of "financial.invoices" would return the entire invoices array.

    Example 2: A configured data path of "financial.invoices.0.info.invoice_number" would return the first invoice number:
    123343

    Example 3: A configured data path of "financial.invoices.info.invoice_date" would return a list of just the invoice dates:
    ["March 23, 2020", "December 26, 2021"]
    */

    let result: T = null;

    //Parse first section out of config data point and check if its json.  if it is, parse its value and call getProperty for the rest of the property path
    if (propertyName.startsWith(".")) {
      console.warn("The configured property path " + propertyName + " starts with a dot.  It will be ignored.");
    }

    //Trim dots from the beginning and end of the configured data point path:
    propertyName = propertyName.replace(this.preOrPostDotPattern, "");

    //If the convenience default message was specified, then determine that message:
    if (this.DEFAULT_MSG === requiredErrorMessage) {
      requiredErrorMessage = "Value for property " + propertyName + " is missing";
    }

    //If it was specified to traverse, then we consider the property name to potentially have a "dotted" path of properties:
    if (traverse) {
      result = this.getDottedProperty<T>(map, propertyName, null, -1);
    }
    else { //we use the full property name as is and try to get a value from the provided map:
      result = (map != null) ? <T>map[propertyName] : null;
    }

    //A value is missing if it is null:
    let missing = (result == null);

    //For strings, trim the value and consider empty strings to be missing:
    if (typeof result === "string") {

      const temp = result.trim();
      if (temp.length === 0) {
        missing = true;
      }
      result = <T><any>temp;
    }

    //If we didn't get a result, then check if the value was required and throw an error as appropriate:
    if (missing) {

      if (requiredErrorMessage != null) {
          throw new MissingPropertyException(propertyName, requiredErrorMessage, errorCode);
      }

      //There was no error message specified, so the value is therefore optional, so apply the default (which itself may be null):
      result = defaultValue;
    }

    return result;
  }

  static convertFlatTreeToHierarchy(flatDataList: any[], idField: string, parentIDField: string, childrenField: string): any[]{



    const originalData = flatDataList.map(item =>
      Object.assign(Object.create(null), item)
    );

    //Start empty temp object
    const objectLookup = Object.create(null);

    //Loop over treelist array reducing down to smaller, hierarchical array:
    const hierarchicalData = originalData.reduce((accumulator: any[], item: any) => {

        // Make sure that each item is added to the objectLookup
        if (!objectLookup[item[idField]]) {
          objectLookup[item[idField]] = item;
          // if doesn't have a child array already, then add one (it will already have one if we already added children to it!)
          if (!item[childrenField]) {
            item[childrenField] = [];
          }
        }
        else {
          // loop over keys and add key / value pairs to object on flat map
          for (let key in item)
            if (item.hasOwnProperty(key)) {
              if (key !== childrenField) {
                objectLookup[item[idField]][key] = item[key];
              }
            }
        }

        //  Make sure there is a parent
        if (!objectLookup[item[parentIDField]]) {
          // add new parent to temp object
          objectLookup[item[parentIDField]] = Object.create(null);
          objectLookup[item[parentIDField]][childrenField] = [];
        }

        // add it to the parent's children array
        objectLookup[item[parentIDField]][childrenField].push(item);

        if ((!item[parentIDField]) || (item[parentIDField] === 0)) {
          // push to final hierarchy array(accumulator)
          accumulator.push(item);
        }
        return accumulator;
      },
      []
    );




    return hierarchicalData;
  }

  static addChildReferencesToFlatTree<T>(flatDataList: T[], idField: string, parentIDField: string, childrenField: string) {



    //Loop over the flat list.  For each item, loop the list again, assembling the list of child IDs (item's that have a parent ID that matches
    // the ID of the item in question):
    //NOTE: Changes are made IN PLACE to the existing array:
    for (let item of flatDataList) {

      item[childrenField] = flatDataList.filter(item => (item[parentIDField] === item[idField])).map(item => item[idField]);
    }


  }

  static clearChildReferencesFromFlatTree<T>(flatDataList: T[], childrenField: string) {



    //Loop over the flat list.  For each item, loop the list again, assembling the list of child IDs (item's that have a parent ID that matches
    // the ID of the item in question):
    //NOTE: Changes are made IN PLACE to the existing array:
    for (let item of flatDataList) {

      item[childrenField].splice(0);
    }


  }

  static convertHierarchicalStructureToFlatTree(hierarchicalData: any[], idField: string, parentIDField: string, childrenField: string): any[]{



    const originalData = hierarchicalData.map(item =>
      Object.assign(Object.create(null), item)
    );

    //Loop over treelist array reducing down to smaller, hierarchical array:
    const flatDataList = originalData.reduce((accumulator: any[], item: any) => {

      //TODO: Finish me!

        // push to final flag array(accumulator)
        accumulator.push(item);

        return accumulator;
      },
      []
    );




    return flatDataList;
  }

  /** does a deep compare of two objects and returns a boolean based on the result
   * @static
   @returns {boolean}
   */
  static jsObjectDeepCompare(a: Object,b: Object): boolean {
    let acompare,bcompare;
    if(a) {
      acompare = JSON.stringify(a);
    }
    else {
      acompare = a;
    }

    if(b) {
      bcompare = JSON.stringify(b);
    }
    else {
      bcompare = b;
    }


    return acompare === bcompare;
  }

  /** gives a clone of the object without having a reference to it. this is not always necessary but in some cases is wanted.
   * @static
   @returns {Object}
   */
  static duplicateDataWithoutReference(data: any): any {

    //return Object.assign({}, data); - only makes a shallow copy! "Deep" or nested objects will be copied by reference!

    //Return a deep copy:
    return JSON.parse(JSON.stringify(data));
  }

  /** converts a blob to a js file
   * @static
   @returns {File}
   */
  static blobToFile(theBlob: Blob, fileName: string): File {
    let b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  }

  /** takes in a js File and converts it into a base 64 encoded string
   * @static
   @param file A js file that we need to get a base 64 encoded image for
   @returns {Promise<string>}
   */
  static getBase64(file: File): Promise<string> {
    //sets up for a promise to be returned
    return new Promise((resolve, reject) => {
      //creates a reader
      const reader = new FileReader();

      //when its completed it returns the result
      // if there is an error it returns the error
      reader.onload = () => resolve(<string>reader.result);
      reader.onerror = error => reject(error);

      //reads data as a base 64 string
      reader.readAsDataURL(file);
    });
  }

  /** polyfill to make sure that we can utilize the path object in browsers that don't natively support it
   * @static
   */
  static getElementPath(element) {
    if (element == null) return [];

    let pathArr = [element];

    while (element.parentElement != null) {
      element = element.parentElement;
      pathArr.push(element)
    }

    return pathArr;
  }

  /** takes in a url and parses it for the base url and any params that follow
   * @static
   @param url the url that we are trying to parse
   @returns {ParsedURL}
   */
  static parseURL(url: string): ParsedURL {

    //splits up the url query and returns the page that is intended, the parameters and an exact match of the original url

    //split based on the '?' to get the first half and second half of the string
    let queryParsed = url.split('?');

    //set the page because we know that its going to be the this first part of the query
    let page = queryParsed[0];

    let paramsSection: any;
    let params: any = {};


    //if there are paramaters then we go through that section
    // go through and grab each parameter key and value and add it to the params object
    if (queryParsed[1]) {
      //get the area where the paramaters are and split them further based on the '&'
      paramsSection = queryParsed[1].split('&');

      for (let i = 0; i < paramsSection.length; i++) {
        params[paramsSection[i].split('=')[0]] = paramsSection[i].split('=')[1];
      }
    }

    //return the final object
    return {page: page, params: params, original: url};
  }

  /** take a number and pad that number with zeroes of the size given
   * @static
   @param num - a number that we want to pad with zeroes for display
   @param size - how many zeroes we want to pad on the num
   @returns {string}
   */
  static padWithZeroes(num: number, size: number): string {
    //takes a number and a size and appends the proper amount of 0s to the number
    //example: if number = 5 and size = 3 then the output of this function would be 005

    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  /** takes in a string and determines how much memory it actually takes up.
   * @static
   @param raw - determines whether or not to just send back the memory as a string or as a readable version of that memory EX. 1024 bytes vs 1 mb
   @param str - the string that we want to calculate the memory size for
   @returns {string}
   */
  static getMemorySizeOfString(str: string, raw: boolean): string {
    let s: number;

    //just in case the string is undefined
    if (str) {
      // returns the byte length of an utf8 string
      s = str.length;
      for (let i = str.length - 1; i >= 0; i--) {
        let code = str.charCodeAt(i);
        if (code > 0x7f && code <= 0x7ff) s++;
        else if (code > 0x7ff && code <= 0xffff) s += 2;
        if (code >= 0xDC00 && code <= 0xDFFF) i--; //trail surrogate
      }
    }
    else {
      s = 0;
    }

    //display only the byte amount
    if (raw) {
      return s.toString();
    }
  }

  /** find the index of a string within a string array
   * @static
   @param str - the string to search for within the array
   @param strArray - the array to search for the string
   @returns {number}
   */
  static searchStringInArray(str: string, strArray: string[]): number {
    //check for an exact match within the string array
    for (let j = 0; j < strArray.length; j++) {
      if (strArray[j] === str) return j;
    }
    return -1;
  }

  /** gets an object keyed of duplicate values within an array
   * @static
   @param key - this can be given to specify a key to find the unique value of
   @param arr - an array we want to find unique values for
   @returns {Object}
   */
  static getListOfDuplicateValues(arr: any[], key: string): {} {
    let temp = {}, final = {};

    //loop through the array
    // if we didn't already determine that there are duplicates and the temporary object hasn't kept track of that key yet
    //    then track it in the temporary object
    // otherwise we know that there is a duplicate so set up the final object to have the key
    for (let i = 0; i < arr.length; i++) {
      if (!final[arr[i]] && !temp[arr[i][key]]) {
        temp[arr[i][key]] = 1;
      }
      else {
        final[arr[i][key]] = true;
      }
    }

    return final;
  }

  /** gets an array of duplicate values within an array
   * @static
   @param field - this can be given to specify a key to find the unique value of
   @param arr - an array we want to find unique values for
   @returns {any[]}
   */
  static getUniqueArrayValues(arr: any[], field: string): any[] {
    //check the object if the value exists
    // if it doesn't exist then add it to the new array and add it to the object
    // it it does exist then just keep going through the array
    let n = {}, r = [];


    if (field === '') {
      for (let i = 0; i < arr.length; i++) {
        if (!n[arr[i]]) {
          n[arr[i]] = true;
          r.push(arr[i]);
        }
      }
    }
    else {
      for (let i = 0; i < arr.length; i++) {
        if (!n[arr[i][field]]) {
          n[arr[i][field]] = true;
          r.push(arr[i][field]);
        }
      }
    }
    return r;
  }

  /** takes in a url and file name and mimetype and does a fetch on that url and returns a js file
   * @static
   @param url - a url that we are trying to go to in order to fetch a js file
   @param file_name - the name of the new file
   @param mimeType - the mimetype of the file you are trying to retrieve
   @returns {Promise<File>}
   */
  static fileURItoJSFile(url: string, file_name: string, mimeType: string): Promise<File> {

    return fetch(url, {method: 'GET'})
        .then((res) => {

          if ((!res.ok) || (res.status < 200) || (res.status >= 400)) {

            return res.json().then((errorBody) => {

              throw new HttpErrorResponse({
                error: errorBody,
                status: res.status,
                statusText: res.statusText,
                url: url
              });
            });
          }
          else {
            return res.arrayBuffer();
          }
        })
        .then((buf) => {
          return new File([buf], file_name, {type: mimeType});
        });
  }

  /** helper function to quickly download a js file from a specific url
   * @static
   @param url - a url that we are trying to go to in order to fetch a js file
   @param file_name - the name of the new file
   @param mimeType - the mimetype of the file you are trying to retrieve
   */
  static downloadFileFromUrl(url: string, file_name: string, mimeType: string): Promise<void> {

    //converts the url source into a js file
    return GeneralUtilService.fileURItoJSFile(url, file_name, mimeType).then((data) => {

      //takes the newly created file and downloads it properly
      download(data, file_name, mimeType);
    });
  }

  static mockStringEnum<T extends string>(stringList: Array<T>): {[K in T]: K} {
    return stringList.reduce((accumulator, key) => {
      accumulator[key] = key;
      return accumulator;
    }, Object.create(null));
  }

  static copyTextToClipboard(text: string){

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  static formatTimeDuration(seconds: number, alwaysIncludeHours: boolean = false): string {

    let hrs: number = 0;
    let min: number = 0;
    let sec: number = seconds;

    //Check to see if there are any minutes
    if (sec >= 60) {

      //Get number of minutes from seconds
      min = Math.floor(sec / 60);

      //Get the remaining seconds
      sec = sec % 60;
    }

    //Check to see if there are any hours
    if (min >= 60) {

      //Get number of hours from minutes
      hrs = Math.floor(min / 60);

      //Get number of min after removing hours.
      min = min % 60;
    }

    //Pad with a zero if necessary:
    const hh = (hrs < 10) ? "0" + hrs.toString() : hrs.toString();
    const mm = (min < 10) ? "0" + min.toString() : min.toString();
    const ss = (sec < 10) ? "0" + sec.toString() : sec.toString();

    //Include the hours if the total number of seconds is greater than an hours-worth or if the flag has been
    //set to always include hours:
    if ((seconds >= 3600) || (alwaysIncludeHours)) {
      return hh + ":" + mm + ":" + ss;
    } else {
      return mm + ":" + ss;
    }
  }

  static replaceIllegalFileSystemCharacters(fileName: string, replacement: string) {

    return fileName.replace(this.illegalFileNameCharsPattern, replacement);

  }

  static setTimeoutPromise<T>(delay: number, v: T): Promise<T> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(v), delay);
    });
  }
}
